import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { BulletinBoardDataListQuery } from '../models/bulletin-board-data-list-query.model';

// Satisfaction
export const applyFilter = createAction(
  '[Doctor Satisfaction Page] Apply Export Satisfaction List Page Filter',
  props<{ query: Partial<BulletinBoardDataListQuery> }>()
);

export const resetFilter = createAction('[Doctor Satisfaction Page] Reset Export Satisfaction List Page Filter');

export const changeSort = createAction(
  '[Doctor Satisfaction Page] Change Export Satisfaction List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Doctor Satisfaction Page] Change Export Satisfaction List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Doctor Satisfaction Page] Change Export Satisfaction List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Doctor Satisfaction Page] Change Export Satisfaction List Page Size',
  props<{ size: number }>()
);

