import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromList from './role-management-list.reducer';
import * as fromEdit from './role-management-edit.reducer';

export const featureKey = 'roleManagement';

export interface RoleManagementState {
  [fromList.featureKey]: fromList.State;
  [fromEdit.featureKey]: fromEdit.State;
}

export interface State {
  [featureKey]: RoleManagementState;
}

export function reducers(state: RoleManagementState, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEdit.featureKey]: fromEdit.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getRoleManagementState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// Role List
export const getListState = createSelector(
  getRoleManagementState,
  state => state[fromList.featureKey]
);

export const getListFilter = createSelector(
  getListState,
  state => state.query
);

export const getRoleListIsLoading = createSelector(
  getListState,
  state => state.isLoading
);

// Edit Role
export const getEditState = createSelector(
  getRoleManagementState,
  state => state[fromEdit.featureKey]
);

export const getRoleDetail = createSelector(
  getEditState,
  state => state.roleDetail
)

export const getNewRoleId = createSelector(
  getEditState,
  state => state.roleId
)

export const getRoleMenuTreeList = createSelector(
  getEditState,
  state => state.roleMenuTreeList
)

export const getCurrentRoleMenuTreeList = createSelector(
  getEditState,
  state => state.currentRoleMenuTreeList
);

export const getEditingIsPending = createSelector(
  getEditState,
  state => state.isPending
)
