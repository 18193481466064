import { combineReducers, Action, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDocClassification from './doc-classification-detail.reducer';
import * as fromDocClassificationTree from './doc-classification-tree.reducer';

export const featureKey = 'docClassification';

export interface DocClassificationState {
  [fromDocClassification.featureKey]: fromDocClassification.State;
  [fromDocClassificationTree.featureKey]: fromDocClassificationTree.State;
}

export interface State {
  [featureKey]: DocClassificationState;
}

export function reducers(state: DocClassificationState | undefined, action: Action) {
  return combineReducers({
    [fromDocClassification.featureKey]: fromDocClassification.reducer,
    [fromDocClassificationTree.featureKey]: fromDocClassificationTree.reducer,
  })(state, action);
}

export const getDocumentationState = createFeatureSelector<State>('documentation');
export const getDocClassificationState = createSelector(
  getDocumentationState,
  state => state[featureKey]
);

export const getDocClassification = createSelector(
  getDocClassificationState,
  state => state.docClassificationDetail.data
);

export const getIsDetailPending = createSelector(
  getDocClassificationState,
  state => state.docClassificationDetail.isPending
);

export const getDocClassificationTree = createSelector(
  getDocClassificationState,
  state => state.docClassificationTree.data
);
