import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SettingsTargetOrg } from '../models/settings-target-managemen.model';
import { SettingsTargetManagemenApiActions } from '../actions';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'info';

export interface State extends EntityState<Partial<SettingsTargetOrg>> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<SettingsTargetOrg>> = createEntityAdapter<SettingsTargetOrg>({
  sortComparer: false,

});

export const initialState = {

};

export const reducer = createReducer(
  initialState,
  on(
    SettingsTargetManagemenApiActions.settingsTargetOrg,
    (state, { org }) => ({ ...state, org })
  ),
  on(
    SettingsTargetManagemenApiActions.getSettingTargetBaseInfoSuccess,
    (state, { targetList }) => ({ ...state, targetList })
  ),
  on(
    SettingsTargetManagemenApiActions.getSettingTargetLastYearSuccess,
    (state, { lastYearList }) => ({ ...state, lastYearList })
  ),
  on(
    SettingsTargetManagemenApiActions.getTargetKpiSuccess,
    (state, { kpiList }) => ({ ...state, kpiList, isLoading: true })
  ),
  on(
    // 清除
    SettingsTargetManagemenApiActions.leave,
    (state) => ({
      ...initialState
    })
  )
);
