import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromList from './space-type-list.reducer';
import * as fromEdit from './space-type-edit.reducer';

export const featureKey = 'spaceType';

export interface SpaceTypeState {
  [fromList.featureKey]: fromList.State;
  [fromEdit.featureKey]: fromEdit.State;
}

export interface State {
  [featureKey]: SpaceTypeState;
}

export function reducers(state: SpaceTypeState, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEdit.featureKey]: fromEdit.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getSpaceTypeState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// SpaceType List
export const getListState = createSelector(
  getSpaceTypeState,
  state => state[fromList.featureKey]
);

export const getListFilter = createSelector(
  getListState,
  state => state.query
);

export const getSpaceTypeListIsLoading = createSelector(
  getListState,
  state => state.isLoading
);

// Edit SpaceType
export const getEditState = createSelector(
  getSpaceTypeState,
  state => state[fromEdit.featureKey]
);

export const getSpaceTypeDetail = createSelector(
  getEditState,
  state => state.roleDetail
)

export const getNewSpaceTypeId = createSelector(
  getEditState,
  state => state.roleId
)

export const getSpaceTypeMenuTreeList = createSelector(
  getEditState,
  state => state.roleMenuTreeList
)

export const getCurrentSpaceTypeMenuTreeList = createSelector(
  getEditState,
  state => state.currentSpaceTypeMenuTreeList
)
