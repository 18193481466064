import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { GradeListQuery } from '../models/grade-list-query.model';

export const applyFilter = createAction(
  '[Attestation Grade List Page] Apply Grade List Page Filter',
  props<{ query: Partial<GradeListQuery> }>()
);

export const resetFilter = createAction('[Attestation Grade List Page] Reset Grade List Page Filter');

export const changeSort = createAction(
  '[Attestation Grade List Page] Change Grade List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Attestation Grade List Page] Change Grade List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Attestation Grade List Page] Change Grade List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Attestation Grade List Page] Change Grade List Page Size',
  props<{ size: number }>()
);

export const deleteGrade = createAction(
  '[Attestation Grade List Page] Delete Grade',
  props<{ id: number }>()
);
