import { createReducer, on } from '@ngrx/store';
import { SystemCodesActions } from '../actions';
import { SystemCodes } from '../models/system-codes.model';

export const featureKey = 'systemCodesTree';

export interface State {
  data: SystemCodes | null;
}

export const initialState: State = {
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    // 获取组织架构树
    SystemCodesActions.loadSystemCodesTreeInfoSuccess,
    (state, { data }) => ({
      ...state,
      data
    })
  ),
);
