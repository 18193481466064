import { createAction, props } from '@ngrx/store';

// 获取满意度列表
export const loadSatisfactionList = createAction(
  '[Manage staff inspect data/API] Load Manage staff inspect data List'
);

export const loadSatisfactionListSuccess = createAction(
  '[Manage staff inspect data/API] Load Manage staff inspect dataList Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

// export const loadSatisfactionListSuccess = createAction(
//   '[Doctor Satisfaction/API] Load Export Satisfaction List Success',
//   // props<{ res: {data:{detail:any, avg: any, section: any }};}>()
//   props<{res: any}>()

// );

export const loadSatisfactionListFailure = createAction(
  '[Manage staff inspect data/API] Load Manage staff inspect data List Failure',
  props<{ msg: any }>()
);
