import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromPostGroupList from './post-group-list.reducer';
import * as fromPostGroupDetail from './post-group-detail.reducer';

export const featureKey = 'postGroup';

export interface PostGroupState {
  [fromPostGroupList.featureKey]: fromPostGroupList.State;
  [fromPostGroupDetail.featureKey]: fromPostGroupDetail.State;
}

export interface State {
  [featureKey]: PostGroupState;
}

export function reducers(state: PostGroupState | undefined, action: Action) {
  return combineReducers({
    [fromPostGroupList.featureKey]: fromPostGroupList.reducer,
    [fromPostGroupDetail.featureKey]: fromPostGroupDetail.reducer
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getPostGroupState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// Post Group
export const getPostGroupEntitiesState = createSelector(
  getPostGroupState,
  state => state.postGroupList
);

export const getPostGroupEntitiesIsLoading = createSelector(
  getPostGroupEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getPostGroupIds,
  selectEntities: getPostGroupEntities,
  selectAll: getAllPostGroups,
  selectTotal: getTotalPostGroup
} = fromPostGroupList.adapter.getSelectors(getPostGroupEntitiesState);

export const getPostGroupList = createSelector(
  getAllPostGroups,
  getPostGroupEntitiesState,
  (allPostGroupState, postGroupEntitiesState) => {
    const list = {
      data: allPostGroupState,
      totalCount: postGroupEntitiesState.totalCount
    };
    return list;
  },
);

export const getPostGroupQuery = createSelector(
  getPostGroupEntitiesState,
  state => state.query
);

// get post group detail
export const getPostGroupDetail = createSelector(
  getPostGroupState,
  state => state[fromPostGroupDetail.featureKey].data
)