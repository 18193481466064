import { createAction, props } from '@ngrx/store';
import { Satisfaction } from '../models/satisfaction.model';

// 获取满意度列表
export const loadSatisfactionList = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction List'
);

export const loadSatisfactionListSuccess = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction List Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

// export const loadSatisfactionListSuccess = createAction(
//   '[Doctor Satisfaction/API] Load Export Satisfaction List Success',
//   // props<{ res: {data:{detail:any, avg: any, section: any }};}>()
//   props<{res: any}>()

// );

export const loadSatisfactionListFailure = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction List Failure',
  props<{ msg: any }>()
);

// 获取满意度列表
export const loadSatisfactionComment = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Comment'
);

export const loadSatisfactionCommentSuccess = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Comment Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

export const loadSatisfactionCommentFailure = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Comment Failure',
  props<{ msg: any }>()
);




// 获取客户满意度分析表
export const loadSatisfactionAnalysis = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Analysis'
);

export const loadSatisfactionAnalysisSuccess = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Analysis Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

export const loadSatisfactionAnalysisFailure = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Analysis Failure',
  props<{ msg: any }>()
);
//获取满意度科室调查数量
export const loadSatisfactionDepartments = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Departments Number'
);

export const loadSatisfactionDepartmentsSuccess = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Departments Number Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

export const loadSatisfactionDepartmentsFailure = createAction(
  '[Doctor Satisfaction/API] Load Export Satisfaction Departments Number Failure',
  props<{ msg: any }>()
);




export const exportList = createAction(
  '[Doctor Satisfaction/API] Export Doctor Satisfaction List',
  props<{ query: any }>()
);

export const exportListSuccess = createAction(
  '[Doctor Satisfaction/API] Export Doctor Satisfaction List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Doctor Satisfaction/API] Export Doctor Satisfaction List Failure',
  props<{ msg: any }>()
);

export const exportListComment = createAction(
  '[Doctor Satisfaction/API] Export Doctor Satisfaction Comment List',
  props<{ query: any }>()
);

export const exportListCommentSuccess = createAction(
  '[Doctor Satisfaction/API] Export Doctor Satisfaction Comment List Success',
  props<{ res: any }>()
);

export const exportListCommentFailure = createAction(
  '[Doctor Satisfaction/API] Export Doctor Satisfaction Comment List Failure',
  props<{ msg: any }>()
);