import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { DocProjectQuery } from '../models/doc-project-query.model';

export const applyFilter = createAction(
  '[Doc List Page] Apply Project Report List Page Filter',
  props<{ query: Partial<DocProjectQuery> }>()
);

export const resetFilter = createAction('[Doc List Page] Reset Project Report List Page Filter');

export const changeSort = createAction(
  '[Doc List Page] Change Project Report List Page Sort',
  props<{ currentSort: any }>()
);

export const sort = createAction(
	'[Doc List Page] Project Report Sort',
	props<{ orderBies: QueryOrderBy }>()
);

export const changeOrderBies = createAction(
  '[Doc List Page] Change Project Report List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Doc List Page] Change Project Report List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Doc List Page] Change Project Report List Page Size',
  props<{ size: number }>()
);
