import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromList from './dept-management-list.reducer';
import * as fromEdit from './dept-management-edit.reducer';

export const featureKey = 'departmentManagement';

export interface DepartmentManagementState {
  [fromList.featureKey]: fromList.State;
  [fromEdit.featureKey]: fromEdit.State;
}

export interface State {
  [featureKey]: DepartmentManagementState;
}

export function reducers(state: DepartmentManagementState, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEdit.featureKey]: fromEdit.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('env-service');
export const getDepartmentManagementState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// DepartmentManagement List
export const getListState = createSelector(
  getDepartmentManagementState,
  state => state[fromList.featureKey]
);

export const getListFilter = createSelector(
  getListState,
  state => state.query
);

export const getDepartmentManagementListIsLoading = createSelector(
  getListState,
  state => state.isLoading
);

// Edit DepartmentManagement
export const getEditState = createSelector(
  getDepartmentManagementState,
  state => state[fromEdit.featureKey]
);

export const getDeptDetail = createSelector(
  getEditState,
  state => state.deptDetail
)