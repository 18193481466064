import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AseUploadPageActions, AseUploadApiActions } from '../actions';
import { AseUpload } from '../models/ase-upload.model';

// Upload
import { AseUploadListModel } from '../models/ase-upload-list.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'AseUploadList';

export interface State extends EntityState<Partial<AseUpload>> {
  query: AseUploadListModel;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<AseUpload>> = createEntityAdapter<AseUpload>({
  selectId: (AseUpload: AseUpload) => AseUpload.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    employeeId: null,
    org_operation: null,
    org_area: null,
    org_program: null,
    businessLine: null,
    code: null,
    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true
});

export const reducer = createReducer(
  initialState,
  on(
    AseUploadApiActions.loadAseUploadList,
    (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    AseUploadApiActions.loadAseUploadListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    AseUploadApiActions.loadAseUploadListFailure,
    (state, { msg }) => ({ ...state, msg })
  ),
  on(
    AseUploadPageActions.applyFilter,
    AseUploadPageActions.applyFilterDeliver,
    (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(
    AseUploadPageActions.resetFilter,
    AseUploadPageActions.resetFilterDeliver,
    (state) => ({
    ...state,
    query: initialState.query
  })),
  on(
    AseUploadPageActions.changeSort,
    AseUploadPageActions.changeSortDeliver,
    (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(
    AseUploadPageActions.changeOrderBies,
    AseUploadPageActions.changeOrderBiesDeliver,
    (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(
    AseUploadPageActions.changeCurrentPage,
    AseUploadPageActions.changeCurrentPageDeliver,
    (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(
    AseUploadPageActions.changePageSize,
    AseUploadPageActions.changePageSizeDeliver,
    (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
