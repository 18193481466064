import { props, createAction } from '@ngrx/store';
// import { IssueRecord } from '../../_shared/models/issue-record.model';
// import { InspectionCorrectiveActionTasks } from '../models/issue-inspection-corrective-action-tasks.model';
// import { InspectionTasks } from '../models/issue-inspection-tasks.model';


// 获得列表
export const loadProjectAssessInquiryList = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment List'
);

export const loadProjectAssessInquiryListSuccess = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment List Success',
  props<{ res: { data: any[]; totalCount: number } }>()
);

export const loadProjectAssessInquiryListFailure = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment List Failure',
  props<{ res: any }>()
);

// load detail info
export const loadProjectAssessInquiry = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment',
  props<{ id: number }>()
);

export const loadProjectAssessInquirySuccess = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment Success',
  props<{ res: any }>()
);

export const loadProjectAssessInquiryFailure = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment Failure',
  props<{ msg: any }>()
);

// load inspection forms
// 讀取檢查結果概要
export const loadInspectionResults = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment Inspection Results',
  props<{ id: number }>()
);

export const loadInspectionResultsSuccess = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment Inspection Results Success',
  props<{ res: any }>()
);

export const loadInspectionResultsFailure = createAction(
  '[Peoject Assessment/API] Load Peoject Assessment Inspection Results Failure',
  props<{ res: any }>()
);