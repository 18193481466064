import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromList from './list.reducer';

export const featureKey = 'exportList';

export interface SatisfactionState {
  [fromList.featureKey]: fromList.State;
}

export interface State {
  [featureKey]: SatisfactionState;
}

export function reducers(state: SatisfactionState | undefined, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('env-service');
export const getSatisfactionState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// Message Group
export const getSatisfactionEntitiesState = createSelector(
  getSatisfactionState,
  state => state.list
);

export const getSatisfactionEntitiesIsLoading = createSelector(
  getSatisfactionEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getSatisfactionIds,
  selectEntities: getSatisfactionEntities,
  selectAll: getAllSatisfactions,
  selectTotal: getTotalSatisfaction
} = fromList.adapter.getSelectors(getSatisfactionEntitiesState);

export const getSatisfactionList = createSelector(
  getAllSatisfactions,
  getSatisfactionEntitiesState,
  (allSatisfactionState, satisfactionEntitiesState) => {
    const list = {
      data: allSatisfactionState,
      totalCount: satisfactionEntitiesState.totalCount
    };
    return list;
  },
);

export const getSatisfactionComment = createSelector(
  getAllSatisfactions,
  getSatisfactionEntitiesState,
  (allSatisfactionState1, satisfactionEntitiesState1) => {
    const list = {
      data: allSatisfactionState1,
      totalCount: satisfactionEntitiesState1.totalCount
    };
    return list;
  },
);

export const getSatisfactionQuery = createSelector(
  getSatisfactionEntitiesState,
  state => state.query
);
