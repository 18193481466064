import { saveTargetBasic } from './../actions/settings-target-managemen-api.action';
import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromSettingsTargetManagemen from './settings-target-managemen.reducer';
import * as fromSettingsTargetInfo from './settings-target-info.reducer'
import * as fromSettingsTargetSubmit from './settings-target-submit.reducer'

export const featureKey = 'settingsTargetManagemen';

export interface progressTargetManagemenState {
  [fromSettingsTargetManagemen.featureKey]: fromSettingsTargetManagemen.State;
  [fromSettingsTargetInfo.featureKey]: fromSettingsTargetInfo.State;
  [fromSettingsTargetSubmit.featureKey]: fromSettingsTargetSubmit.State;
}

export interface State {
  [featureKey]: progressTargetManagemenState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromSettingsTargetManagemen.featureKey]: fromSettingsTargetManagemen.reducer,
    [fromSettingsTargetInfo.featureKey]: fromSettingsTargetInfo.reducer,
    [fromSettingsTargetSubmit.featureKey]: fromSettingsTargetSubmit.reducer,
  })(state, action);
}

export const getTargetAndPlanState = createFeatureSelector<State>('target-and-plan');
export const getSettingsTargetState = createSelector(
  getTargetAndPlanState,
  state => state[featureKey]
);

export const getSettingsInfo = createSelector(
  getSettingsTargetState,
  state => state.info
);


export const saveTargetSubmit  = createSelector(
  getSettingsTargetState,
  state => state.submit
)

export const saveTargetBasicInfo  = createSelector(
  saveTargetSubmit,
  state => state.targetBasic
)

export const saveTargetList  = createSelector(
  saveTargetSubmit,
  state => state.targetList
)


export const getTargetInfo  = createSelector(
  getSettingsTargetState,
  state => state.settings
)
