import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { IncidentInvestigationListQuery } from '../models/incident-investigation-list-query.model';

export const applyFilter = createAction(
  '[Incident Investigation Page] Apply Incident Investigation List Page Filter',
  props<{ query: Partial<IncidentInvestigationListQuery> }>()
);

export const resetFilter = createAction('[Incident Investigation Page] Reset Incident Investigation List Page Filter');

export const changeSort = createAction(
  '[Incident Investigation Page] Change Incident Investigation List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Incident Investigation Page] Change Incident Investigation List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Incident Investigation Page] Change Incident Investigation List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Incident Investigation Page] Change Incident Investigation List Page Size',
  props<{ size: number }>()
);
