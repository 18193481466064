import { createAction, props } from '@ngrx/store';
import { RoleBasicInfo } from '../models';

// 获取Role List
export const loadRoleList = createAction(
  '[Role Management/API] Load Role List'
);

export const loadRoleListSuccess = createAction(
  '[Role Management/API] Load Role List Success',
  props<{ res: { data: any[]; totalCount: number } }>()
);

export const loadRoleListFailure = createAction(
  '[Role Management/API] Load Role List Failure',
  props<{ res: any }>()
);

// 根据id获取role detail
export const getRoleDetail = createAction(
  '[Role Management/API] Get Role Detail',
  props<{ id: string }>()
);

export const getRoleDetailSuccess = createAction(
  '[Role Management/API] Get Role Detail Success',
  props<{ res: any }>()
);

export const getRoleDetailFailure = createAction(
  '[Role Management/API] Get Role Detail Failure',
  props<{ res: any }>()
);

// 角色基本信息保存
export const saveRoleBasicInfo = createAction(
  '[Role Management/API] Save Role Basic Info',
  props<{ para: Partial<RoleBasicInfo> }>()
);

export const saveRoleBasicInfoSuccess = createAction(
  '[Role Management/API] Save Role Basic Info Success',
  props<{ res: any }>()
);

export const saveRoleBasicInfoFailure = createAction(
  '[Role Management/API] Save Role Basic Info Failure',
  props<{ res: any }>()
);

// 角色基本信息更新
export const updateRoleBasicInfo = createAction(
  '[Role Management/API] Update Role Basic Info',
  props<{ para: Partial<RoleBasicInfo> }>()
);

export const updateRoleBasicInfoSuccess = createAction(
  '[Role Management/API] Update Role Basic Info Success',
  props<{ res: any }>()
);

export const updateRoleBasicInfoFailure = createAction(
  '[Role Management/API] Update Role Basic Info Failure',
  props<{ res: any }>()
);

// 删除角色
export const deleteRoles = createAction(
  '[Role Management/API] Delete Roles',
  props<{ ids: string }>()
);

export const deleteRolesSuccess = createAction(
  '[Role Management/API] Delete Roles Success',
  props<{ res: any }>()
);

export const deleteRolesFailure = createAction(
  '[Role Management/API] Delete Roles Failure',
  props<{ res: any }>()
);

// 获取角色模块列表（树形）
export const getRoleMenuTreeList = createAction(
  '[Role Management/API] Get Role Menu Tree List'
)

export const getRoleMenuTreeListSuccess = createAction(
  '[Role Management/API] Get Role Menu Tree List Success',
  props<{ res: any }>()
)

export const getRoleMenuTreeListFailure = createAction(
  '[Role Management/API] Get Role Menu Tree List Failure',
  props<{ res: any }>()
)

// 根据树形结构List保存角色权限
export const saveRoleMenuByTreeList = createAction(
  '[Role Management/API] Save Role Permissions By Tree List',
  props<{ id: string, para: any }>()
)
 
export const saveRoleMenuByTreeListSuccess = createAction(
  '[Role Management/API] Save Role Permissions By Tree List Success',
  props<{ res: any }>()
)

export const saveRoleMenuByTreeListFailure = createAction(
  '[Role Management/API] Save Role Permissions By Tree List Failure',
  props<{ res: any }>()
)

// 获取role的permission tree list
export const getCurrentRoleMenuTreeList = createAction(
  '[Role Management/API] Get Current Role Menu Tree List',
  props<{ id: string }>()
)

export const getCurrentRoleMenuTreeListSuccess = createAction(
  '[Role Management/API] Get Current Role Menu Tree List Success',
  props<{ res: any }>()
)

export const getCurrentRoleMenuTreeListFailure = createAction(
  '[Role Management/API] Get Current Role Menu Tree List Failure',
  props<{ res: any }>()
)
