import { props, createAction } from '@ngrx/store';

// 新增巡檢回報
export const addIssueReporting = createAction(
  '[Issue Reporting/API] Add Issue Reporting'
);

export const addIssueReportingSuccess = createAction(
  '[Issue Reporting/API] Add Issue Reporting Success',
  props<{ issueReportingId: number }>()
);

export const addIssueReportingFailure = createAction(
  '[Issue Reporting/API] Add Issue Reporting Failure',
  props<{ res: any }>()
);


// 草稿巡檢回報
export const draftIssueReporting = createAction(
  '[Issue Reporting/API] Draft Issue Reporting'
);

export const draftIssueReportingSuccess = createAction(
  '[Issue Reporting/API] Draft Issue Reporting Success',
  props<{ issueReportingId: number }>()
);

export const draftIssueReportingFailure = createAction(
  '[Issue Reporting/API] Draft Issue Reporting Failure',
  props<{ res: any }>()
);

