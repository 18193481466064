import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromIncidentInvestigationList from './incident-investigation-list.reducer';

export const featureKey = 'incidentInvestigation';

export interface IncidentInvestigationState {
  [fromIncidentInvestigationList.featureKey]: fromIncidentInvestigationList.State;
}

export interface State {
  [featureKey]: IncidentInvestigationState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromIncidentInvestigationList.featureKey]: fromIncidentInvestigationList.reducer,
  })(state, action);
}

export const getIncidentState = createFeatureSelector<State>('incident');
export const getIncidentInvestigationState = createSelector(
  getIncidentState,
  state => state.incidentInvestigation
);

// Incident Investigation
export const getIncidentInvestigationEntitiesState = createSelector(
  getIncidentInvestigationState,
  state => state.incidentInvestigationList
);

export const getIncidentInvestigationEntitiesIsLoading = createSelector(
  getIncidentInvestigationEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getIncidentInvestigationIds,
  selectEntities: getIncidentInvestigationEntities,
  selectAll: getAllIncidentInquiries,
  selectTotal: getTotalIncidentInvestigation
} = fromIncidentInvestigationList.adapter.getSelectors(getIncidentInvestigationEntitiesState);

export const getIncidentInvestigationList = createSelector(
  getAllIncidentInquiries,
  getIncidentInvestigationEntitiesState,
  (allIncidentInvestigationState, incidentInvestigationEntitiesState) => {
    const list = {
      data: allIncidentInvestigationState,
      totalCount: incidentInvestigationEntitiesState.totalCount
    };
    return list;
  },
);

export const getIncidentInvestigationQuery = createSelector(
  getIncidentInvestigationEntitiesState,
  state => state.query
);
