import { createAction, props } from '@ngrx/store';
import { IncidentInvestigation } from '../models/incident-investigation.model';

export const loadIncidentInvestigationList = createAction(
  '[Incident Investigation/API] Load Incident Investigation List'
);

export const loadIncidentInvestigationListSuccess = createAction(
  '[Incident Investigation/API] Load Incident Investigation List Success',
  props<{ res: { data: Partial<IncidentInvestigation>[]; totalCount: number } }>()
);

export const loadIncidentInvestigationListFailure = createAction(
  '[Incident Investigation/API] Load Incident Investigation List Failure',
  props<{ msg: any }>()
);
