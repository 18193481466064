import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromList from './space-group-list.reducer';
import * as fromEdit from './space-group-edit.reducer';

export const featureKey = 'spaceGroup';

export interface SpaceGroupState {
  [fromList.featureKey]: fromList.State;
  [fromEdit.featureKey]: fromEdit.State;
}

export interface State {
  [featureKey]: SpaceGroupState;
}

export function reducers(state: SpaceGroupState, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEdit.featureKey]: fromEdit.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getSpaceGroupState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// SpaceGroup List
export const getListState = createSelector(
  getSpaceGroupState,
  state => state[fromList.featureKey]
);

export const getListFilter = createSelector(
  getListState,
  state => state.query
);

export const getSpaceGroupListIsLoading = createSelector(
  getListState,
  state => state.isLoading
);

// Edit SpaceGroup
export const getEditState = createSelector(
  getSpaceGroupState,
  state => state[fromEdit.featureKey]
);

export const getSpaceGroupDetail = createSelector(
  getEditState,
  state => state.roleDetail
)

export const getNewSpaceGroupId = createSelector(
  getEditState,
  state => state.roleId
)

export const getSpaceGroupMenuTreeList = createSelector(
  getEditState,
  state => state.roleMenuTreeList
)

export const getCurrentSpaceGroupMenuTreeList = createSelector(
  getEditState,
  state => state.currentSpaceGroupMenuTreeList
)
