import { combineReducers, Action, createFeatureSelector, createSelector } from '@ngrx/store';
import * as formSystemCodes from './system-codes-detail.reducer';
import * as formSystemCodesTree from './system-codes-tree.reducer';

export const featureKey = 'SystemCodes';

export interface SystemCodesState {
  [formSystemCodes.featureKey]: formSystemCodes.State;
  [formSystemCodesTree.featureKey]: formSystemCodesTree.State;
}

export interface State {
  [featureKey]: SystemCodesState;
}

export function reducers(state: SystemCodesState | undefined, action: Action) {
  return combineReducers({
    [formSystemCodes.featureKey]: formSystemCodes.reducer,
    [formSystemCodesTree.featureKey]: formSystemCodesTree.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getSystemCodesState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

export const getSystemCodes = createSelector(
  getSystemCodesState,
  state => state.systemCodesDetail.data
);

export const getSystemCodesTree = createSelector(
  getSystemCodesState,
  state => state.systemCodesTree.data
);
