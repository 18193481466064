import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { MessageGroupListQuery } from '../models/message-group-list-query.model';

export const applyFilter = createAction(
  '[Message Group Page] Apply Message Group List Page Filter',
  props<{ query: Partial<MessageGroupListQuery> }>()
);

export const resetFilter = createAction('[Message Group Page] Reset Message Group List Page Filter');

export const changeSort = createAction(
  '[Message Group Page] Change Message Group List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Message Group Page] Change Message Group List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Message Group Page] Change Message Group List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Message Group Page] Change Message Group List Page Size',
  props<{ size: number }>()
);
