import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromList from './doc-project.reducer';
import * as fromEditing from './doc-project-editing.reducer';

export const featureKey = 'docProject';

export interface DocListState {
  [fromList.featureKey]: fromList.State;
  [fromEditing.featureKey]: fromEditing.State;
}

export interface State {
  [featureKey]: DocListState;
}

export function reducers(state: DocListState | undefined, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEditing.featureKey]: fromEditing.reducer,
  })(state, action);
}

export const getDocumentationState = createFeatureSelector<State>('documentation');
export const getDocListRecordState = createSelector(
  getDocumentationState,
  state => state[featureKey]
);

// list
// ----------------------------------------------------------------------------

export const getDocListEntitiesState = createSelector(
  getDocListRecordState,
  state => state.listProject
);

export const getDocListEntitiesIsLoading = createSelector(
  getDocListEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getDocProjectListIds,
  selectEntities: getDocListEntities,
  selectAll: getAllDocProjectList,
  selectTotal: getTotalDocProjectList
} = fromList.adapter.getSelectors(getDocListEntitiesState);

export const getDocList = createSelector(
  getAllDocProjectList,
  getDocListEntitiesState,
  (allDocListState, DocListEntitiesState) => {
    const list = {
      data: allDocListState,
      totalCount: DocListEntitiesState.totalCount
    };
    return list;
  },
);

export const getDocListQuery = createSelector(
  getDocListEntitiesState,
  state => state.query
);

// edit
