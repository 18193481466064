import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { InjuredPeopleListQuery } from '../models/injured-people-list-query.model';

export const applyFilter = createAction(
  '[Injured People Page] Apply Injured People List Page Filter',
  props<{ query: Partial<InjuredPeopleListQuery> }>()
);

export const resetFilter = createAction('[Injured People Page] Reset Injured People List Page Filter');

export const changeSort = createAction(
  '[Injured People Page] Change Injured People List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Injured People Page] Change Injured People List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Injured People Page] Change Injured People List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Injured People Page] Change Injured People List Page Size',
  props<{ size: number }>()
);
