import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SettingsTargetInfo } from '../models/settings-target-managemen.model';
import { SettingsTargetManagemenApiActions } from '../actions';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'submit';

export type State = SettingsTargetInfo | null;

export const initialState = {

};

export const reducer = createReducer(
  initialState,
  on(
    SettingsTargetManagemenApiActions.saveTargetBasic,
    (state, { targetBasic }) => ({ ...state, ...targetBasic })
  ),
  on(
    SettingsTargetManagemenApiActions.saveTargetList,
    (state, { kpiDetailDTOS }) => ({ ...state, kpiDetailDTOS })
  ),
  on(
    // 清除
    SettingsTargetManagemenApiActions.leave,
    (state) => ({
      ...initialState
    })
  )
);
