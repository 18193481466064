import { createAction, props } from '@ngrx/store';
import { DocClassification } from '../models/doc-classification.model';

// 讀取合規文件
export const loadDetailInfo = createAction(
    '[Doc Classification/API] Load Doc Classification Detail Info',
    props<{ id: number }>()
);

export const loadDetailInfoSuccess = createAction(
    '[Doc Classification/API] Load Doc Classification Detail Info Success',
    props<{ data: any }>()
);

export const loadDetailInfoFailure = createAction(
    '[Doc Classification/API] Load Doc Classification Incident Detail Info Failure',
    props<{ msg: string }>()
);

// 新增合規文件
export const addDocClassification = createAction(
    '[Doc Classification/API] Add Doc Classification Detail',
    props<{ detail: Partial<DocClassification> }>()
);

export const addDocClassificationSuccess = createAction(
    '[Doc Classification/API] Add Doc Classification Detail Success',
    props<{ res: any }>()
);

export const addDocClassificationFailure = createAction(
    '[Doc Classification/API] Add Doc Classification Detail Failure',
    props<{ msg: string }>()
);

// 更新合規文件
export const updateDetail = createAction(
    '[Doc Classification/API] Update Doc Classification Detail',
    props<{ detail: Partial<DocClassification> }>()
);

export const updateDetailSuccess = createAction(
    '[Doc Classification/API] Update Doc Classification Detail Success',
    props<{ res: any }>()
);

export const updateDetailFailure = createAction(
    '[Doc Classification/API] Update Doc Classification Detail Failure',
    props<{ msg: string }>()
);

// 刪除合規文件
export const deleteDocClassifications = createAction(
    '[Doc Classification/API] Delete Doc Classification Detail',
    props<{ ids: number[] }>()
);

export const deleteDocClassificationsSuccess = createAction(
    '[Doc Classification/API] Delete Doc Classification Detail Success',
    props<{ res: any }>()
);

export const deleteDocClassificationsFailure = createAction(
    '[Doc Classification/API] Delete Doc Classification Detail Failure',
    props<{ msg: string }>()
);

// 讀取合規文件樹
export const loadDocClassificationTreeInfo = createAction(
    '[Doc Classification/API] Load Doc Classification Tree Info'
);

export const loadDocClassificationTreeInfoSuccess = createAction(
    '[Doc Classification/API] Load Doc Classification Tree Info Success',
    props<{ data: any }>()
);

export const loadDocClassificationTreeInfoFailure = createAction(
    '[Doc Classification/API] Load Doc Classification Tree Info Failure',
    props<{ msg: string }>()
);
