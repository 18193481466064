import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromProgressTargetManagemenList from './progress-target-managemen-list.reducer';

export const featureKey = 'progressTargetManagemen';

export interface progressTargetManagemenState {
  [fromProgressTargetManagemenList.featureKey]: fromProgressTargetManagemenList.State;
}

export interface State {
  [featureKey]: progressTargetManagemenState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromProgressTargetManagemenList.featureKey]: fromProgressTargetManagemenList.reducer,
  })(state, action);
}

export const getTargetAndPlanState = createFeatureSelector<State>('target-and-plan');
export const getprogressTargetManagemenState = createSelector(
  getTargetAndPlanState,
  state => state[featureKey]
);

export const getprogressTargetManagemenEntitiesState = createSelector(
  getprogressTargetManagemenState,
  state => state.progressTargetList
);

export const getprogressTargetManagemenEntitiesIsLoading = createSelector(
  getprogressTargetManagemenEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getprogressTargetManagemenIdfs,
  selectEntities: getprogressTargetManagemenEntities,
  selectAll: getAllprogressTargetManagemenInquiries,
  selectTotal: getTotalprogressTargetManagemen
} = fromProgressTargetManagemenList.adapter.getSelectors(getprogressTargetManagemenEntitiesState);

export const getprogressTargetManagemenList = createSelector(
  getAllprogressTargetManagemenInquiries,
  getprogressTargetManagemenEntitiesState,
  (allprogressTargetManagemenState, progressTargetManagemenEntitiesState) => {
    const list = {
      data: allprogressTargetManagemenState,
      totalCount: progressTargetManagemenEntitiesState.totalCount
    };
    return list;
  },
);

export const getprogressTargetManagemenQuery = createSelector(
  getprogressTargetManagemenEntitiesState,
  state => state.query
);
