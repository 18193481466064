import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PostGroupPageActions, PostGroupApiActions } from '../actions';
import { PostGroup } from '../models/post-group.model';

// Query
import { PostGroupListQuery } from '../models/post-group-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'postGroupList';

export interface State extends EntityState<Partial<PostGroup>> {
  query: PostGroupListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<PostGroup>> = createEntityAdapter<PostGroup>({
  selectId: (postGroup: PostGroup) => postGroup.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    groupName: null, // 群组名称
    org: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true
});

export const reducer = createReducer(
  initialState,
  on(PostGroupApiActions.loadPostGroupList, (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    PostGroupApiActions.loadPostGroupListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    PostGroupApiActions.loadPostGroupListFailure,
    (state, { msg }) => ({ ...state, msg })
  ),
  on(PostGroupPageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(PostGroupPageActions.resetFilter, (state) => ({
    ...state,
    query: initialState.query
  })),
  on(PostGroupPageActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(PostGroupPageActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(PostGroupPageActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(PostGroupPageActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
