import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { ManageStaffInspectDataListQuery } from '../models/manage-staff-inspect-data-list-query.model';

// Satisfaction
export const applyFilter = createAction(
  '[Manage staff inspect data Page] Apply Manage staff inspect data List Page Filter',
  props<{ query: Partial<ManageStaffInspectDataListQuery> }>()
);

export const resetFilter = createAction('[Manage staff inspect data Page] Reset Export Manage staff inspect data List Page Filter');

export const changeSort = createAction(
  '[Manage staff inspect data Page] Change Export Manage staff inspect data List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Manage staff inspect data Page] Change Export Manage staff inspect data List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Manage staff inspect data Page] Change Export Manage staff inspect data List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Manage staff inspect data Page] Change Export Manage staff inspect data List Page Size',
  props<{ size: number }>()
);

