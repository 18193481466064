import { createReducer, on } from '@ngrx/store';
import {
  IssueReportingApiActions as ApiAction,
  IssueReportingEditPageActions as PageAction,
} from '../actions';
import { IssueReporting } from '../models/issue-reporting.model';

export const featureKey = 'edit';

export type State = IssueReporting | null;

export const initialState: State = {
  basicInfo: null,
  findings: []
};

export const reducer = createReducer(
  initialState,
  on(
    PageAction.updateBasicInfo,
    (state, { basicInfo }) => ({ ...state, basicInfo })
  ),
  on(
    PageAction.updateFindingIssues,
    (state, { issues }) => ({ ...state, findings: issues })
  ),
  on(
    // 清除
    PageAction.leave,
    (state) => ({
      ...initialState
    })
  )
);
