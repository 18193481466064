import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { InjuredPeople } from '../models/injured-people.model';
import { InjuredPeopleApiActions, InjuredPeoplePageActions } from '../actions';

// Query
import { InjuredPeopleListQuery } from '../models/injured-people-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'injuredPeopleList';

export interface State extends EntityState<Partial<InjuredPeople>> {
  query: InjuredPeopleListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<InjuredPeople>> = createEntityAdapter<InjuredPeople>({
  selectId: (injuredPeople: InjuredPeople) => injuredPeople.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    name: null,
    gender: null,
    businessLine: null,
    org_operation: null,
    org_area: null,
    org_program: null,
    incidentDateFrom: null,
    incidentDateTo: null,
    applyForInsurance: null,
    reportOverdue: null,
    returnToWork: null,
    approvalStatus: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true
});

export const reducer = createReducer(
  initialState,
  on(InjuredPeopleApiActions.loadInjuredPeopleList, (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    InjuredPeopleApiActions.loadInjuredPeopleListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    InjuredPeopleApiActions.loadInjuredPeopleListFailure,
    (state, { msg }) => ({ ...state, msg })
  ),
  on(InjuredPeoplePageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(InjuredPeoplePageActions.resetFilter, (state) => ({
    ...state,
    query: initialState.query
  })),
  on(InjuredPeoplePageActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(InjuredPeoplePageActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(InjuredPeoplePageActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(InjuredPeoplePageActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
