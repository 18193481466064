import { createAction, props } from '@ngrx/store';
import { ProgressPlanManagemen } from '../models/progress-plan-managemen.model';

export const loadProgressPlanList = createAction(
  '[Progress Plan/API] Load Progress Plan List'
);

export const loadProgressPlanListSuccess = createAction(
  '[Progress Plan/API1219] Load Progress Plan List Success',
  props<{ res: { data: Partial<ProgressPlanManagemen>[]; totalCount: number } }>()
);

export const loadSettingsPlanListFailure = createAction(
  '[Inspection Record/API1219] Load Progress Plan Managemen List Failure',
  props<{ res: any }>()
);

export const delProgressPlan = createAction(
  '[Del Progress Plan/API] Del Progress Plan',
  props<{ id: any }>()
);

export const delProgressPlanSuccess = createAction(
  '[Del Progress Plan/API1219] Del Progress Plan Success',
);

export const delProgressPlanFailure = createAction(
  '[Del Progress Plan/API1219] Del Progress Plan Failure',
  props<{ msg: any }>()
);



// 导出列表
export const exportList = createAction(
  '[Progress Plan Export/API1219] Progress Plan Export List',
  props<{ para: any }>()
);

export const exportListSuccess = createAction(
  '[Progress Plan Export/API1219] Progress Plan Export List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Progress Plan Export/API1219] Progress Plan Export List Failure',
  props<{ msg: any }>()
);
