import { createAction, props } from '@ngrx/store';
import { AseManagement } from '../models/ase-management.model';

// 获取ase检查列表
export const loadASEManagementFormList = createAction(
  '[ASE Management/API] Load Space Inspection Form List'
);

export const loadASEManagementFormListSuccess = createAction(
  '[ASE Management/API] Load Space Inspection Form List Success',
  props<{ res: { data: Partial<AseManagement>[]; totalCount: number } }>()
);

export const loadASEManagementFormListFailure = createAction(
  '[ASE Management/API] Load Space Inspection Form List Failure',
  props<{ msg: any }>()
);

// 保存ase表格
export const updateASEManagement = createAction(
  '[Add ASE Management/API] Update ASE Management Form',
  props<{ data: any }>()
);

export const updateASEManagementSuccess = createAction(
  '[Add ASE Management/API] Update ASE Management Form Success',
  props<{ res: any }>()
);

export const updateASEManagementFailure = createAction(
  '[Add ASE Management/API] Update ASE Management Form Failure',
  props<{ msg: any }>()
);