import { createReducer, on } from '@ngrx/store';
import {
  SettingsPlanManagemenApiActions as PageAction,
} from '../actions';
import { getSettingsPlanManagemen } from '../models/settings-plan-managemen.model';

export const featureKey = 'editing';

export type State = getSettingsPlanManagemen | null;

export const initialState: State = null;

export const reducer = createReducer(
  initialState,
  on(
    // 讀取
    PageAction.getTargetPlanSuccess,
    (state, { res }) => ({
      ...res
    })
  ),
  on(
    // 清除
    PageAction.leave,
    (state) => ({
      ...initialState
    })
  ),
);
