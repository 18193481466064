import { createAction, props } from '@ngrx/store';
import { IncidentInquiry } from '../models/incident-inquiry.model';

export const loadIncidentInquiryList = createAction(
  '[Incident Inquiry/API] Load Incident Inquiry List'
);

export const loadIncidentInquiryListSuccess = createAction(
  '[Incident Inquiry/API] Load Incident Inquiry List Success',
  props<{ res: { data: Partial<IncidentInquiry>[]; totalCount: number } }>()
);

export const loadIncidentInquiryListFailure = createAction(
  '[Incident Inquiry/API] Load Incident Inquiry List Failure',
  props<{ msg: any }>()
);

export const exportList = createAction(
  '[Incident Inquiry/API] Export Incident Inquiry List'
);

export const exportListSuccess = createAction(
  '[Incident Inquiry/API] Export Incident Inquiry List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Incident Inquiry/API] Export Incident Inquiry List Failure',
  props<{ msg: any }>()
);
