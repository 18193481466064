import { createAction, props } from '@ngrx/store';
import { PostGroup } from '../models/post-group.model';

// 获取群组列表
export const loadPostGroupList = createAction(
  '[Post Group/API] Load Post Group List'
);

export const loadPostGroupListSuccess = createAction(
  '[Post Group/API] Load Post Group List Success',
  props<{ res: { data: Partial<PostGroup>[]; totalCount: number } }>()
);

export const loadPostGroupListFailure = createAction(
  '[Post Group/API] Load Post Group List Failure',
  props<{ msg: any }>()
);

// 创建群组
export const createPostGroup = createAction(
  '[Post Group/API] Create Post Group',
  props<{ groupName: string, jobIds: any[] }>()
);

export const createPostGroupSuccess = createAction(
  '[Post Group/API] Create Post Group Success',
  props<{ res: { data: Partial<PostGroup>[]; totalCount: number } }>()
);

export const createPostGroupFailure = createAction(
  '[Post Group/API] Create Post Group Failure',
  props<{ msg: any }>()
);

// 更新群组
export const updatePostGroup = createAction(
  '[Post Group/API] Update Post Group',
  props<{ id: number, groupName: string, jobIds: any[] }>()
);

export const updatePostGroupSuccess = createAction(
  '[Post Group/API] Update Post Group Success',
  props<{ res: number }>()
);

export const updatePostGroupFailure = createAction(
  '[Post Group/API] Update Post Group Failure',
  props<{ msg: any }>()
);

// 获取群组详细信息
export const getPostGroupDetail = createAction(
  '[Post Group/API] Get Post Group Detail',
  props<{ id: number }>()
)

export const getPostGroupDetailSuccess = createAction(
  '[Post Group/API] Get Post Group Detail Success',
  props<{ res: any }>()
)

export const getPostGroupDetailFailure = createAction(
  '[Post Group/API] Get Post Group Detail Failure',
  props<{ msg: any }>()
)

// 删除群组
export const deletePostGroup = createAction(
  '[Post Group/API] Delete Post Group',
  props<{ ids: string }>()
)

export const deletePostGroupSuccess = createAction(
  '[Post Group/API] Delete Post Group Detail Success',
  props<{ res: any }>()
)

export const deletePostGroupFailure = createAction(
  '[Post Group/API] Delete Post Group Detail Failure',
  props<{ msg: any }>()
)
