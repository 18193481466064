import { createReducer, on } from '@ngrx/store';
import {
  PostGroupApiActions,
  PostGroupPageActions,
} from '../actions';

export const featureKey = 'postGroupDetail';

export interface State {
  data: any | null
};

export const initialState: State = {
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    // 获取群组详情成功
    PostGroupApiActions.getPostGroupDetailSuccess,
    (state, { res }) => ({
      state,
      data: res
    })
  ),
  // on(
  //   // 清除歷史版本表單資料
  //   EmployeeEditPageActions.leave,
  //   (state) => ({
  //     ...initialState
  //   })
  // )
);