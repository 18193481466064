import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { IncidentInquiryListQuery } from '../models/incident-inquiry-list-query.model';

export const applyFilter = createAction(
  '[Incident Inquiry Page] Apply Incident Inquiry List Page Filter',
  props<{ query: Partial<IncidentInquiryListQuery> }>()
);

export const resetFilter = createAction('[Incident Inquiry Page] Reset Incident Inquiry List Page Filter');

export const changeSort = createAction(
  '[Incident Inquiry Page] Change Incident Inquiry List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Incident Inquiry Page] Change Incident Inquiry List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Incident Inquiry Page] Change Incident Inquiry List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Incident Inquiry Page] Change Incident Inquiry List Page Size',
  props<{ size: number }>()
);
