import {
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';

import * as fromRoot from '@_core/reducers';
import * as fromFacility from '../facility/reducers';

export const featureKey = 'physical-structure';

export interface PhysicalStructureState {
  [fromFacility.featureKey]: fromFacility.State;
}

export interface State extends fromRoot.State {
  [featureKey]: PhysicalStructureState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromFacility.featureKey]: fromFacility.reducers,
  })(state, action);
}

export const getPhysicalStructureState = createFeatureSelector<State, PhysicalStructureState>(
  featureKey
);
