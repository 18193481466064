import { createAction, props } from '@ngrx/store';
import { BulletinBoardData } from '../models/bulletin-board-data.model';

// 获取满意度列表
export const loadBulletinList = createAction(
  '[Load Bulletin/API] Load Load Bulletin List'
);

export const loadBulletinListSuccess = createAction(
  '[Load Bulletinn/API] Load Load Bulletin List Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

// export const loadSatisfactionListSuccess = createAction(
//   '[Doctor Satisfaction/API] Load Export Satisfaction List Success',
//   // props<{ res: {data:{detail:any, avg: any, section: any }};}>()
//   props<{res: any}>()

// );

export const loadBulletinListFailure = createAction(
  '[Load Bulletin/API] Load Load Bulletin List Failure',
  props<{ msg: any }>()
);





// 获取客户满意度分析表
export const  loadBulletinfactionAnalysis = createAction(
  '[Load Bulletin/API] Load Export Bulletin Analysis'
);

export const  loadBulletinfactionAnalysisSuccess = createAction(
  '[Load Bulletin/API] Load Export Bulletin Analysis Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

export const  loadBulletinfactionAnalysisFailure = createAction(
  '[Load Bulletin/API] Load Export Bulletin Analysis Failure',
  props<{ msg: any }>()
);
//获取满意度科室调查数量
export const  loadBulletinfactionDepartments = createAction(
  '[Load Bulletin/API] Load Export Load Bulletin Departments Number'
);

export const loadBulletinfactionDepartmentsSuccess = createAction(
  '[Load Bulletin/API] Load Load Bulletin Departments Number Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

export const  loadBulletinfactionDepartmentsFailure = createAction(
  '[Load Bulletin/API] Load Export Bulletin Number Failure',
  props<{ msg: any }>()
);




export const exportList = createAction(
  '[Load Bulletin/API] Export Bulletin List',
  props<{ query: any }>()
);

export const exportListSuccess = createAction(
  '[Load Bulletin/API] Export Bulletin List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Load Bulletin/API] Export Bulletin List Failure',
  props<{ msg: any }>()
);

export const exportListComment = createAction(
  '[Load Bulletin/API] Export Bulletin Comment List',
  props<{ query: any }>()
);

export const exportListCommentSuccess = createAction(
  '[Load Bulletin/API] Export Bulletin Comment List Success',
  props<{ res: any }>()
);

export const exportListCommentFailure = createAction(
  '[Load Bulletin/API] Export Bulletin Comment List Failure',
  props<{ msg: any }>()
);
