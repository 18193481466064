import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { ProgressTargetManagemenListQuery } from '../models/progress-target-managemen-list-query.model';

export const applyFilter = createAction(
  '[Progress Target ManagemenPage] Apply Progress Target Managemen List Page Filter',
  props<{ query: Partial<ProgressTargetManagemenListQuery> }>()
);

export const resetFilter = createAction('[Progress Target Managemen Page] Reset Progress Target Managemen List Page Filter');

export const changeSort = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Page Size',
  props<{ size: number }>()
);

export const applyIssueReportFilter = createAction(
  '[Progress Target Managemen Page] Apply Progress Target Managemen List Page Filter',
  props<{ query: Partial<ProgressTargetManagemenListQuery> }>()
);

export const resetIssueReportFilter = createAction('[Progress Target Managemen Page] Reset Progress Target Managemen List Page Filter');

export const changeIssueReportSort = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Page Sort',
  props<{ currentSort: any }>()
);

export const changeIssueReportOrderBies = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeIssueReportCurrentPage = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Current Page',
  props<{ page: number }>()
);

export const changeIssueReportPageSize = createAction(
  '[Progress Target Managemen Page] Change Progress Target Managemen List Page Size',
  props<{ size: number }>()
);
