import { createAction, props } from '@ngrx/store';
import { AseUpload } from '../models/ase-upload.model';

// 获取Ase检查列表（环境服务）
export const loadAseUploadList = createAction(
  '[Ase Upload/API] Load Ase Upload List'
);

export const loadAseUploadListSuccess = createAction(
  '[Ase Upload/API] Load Ase Upload List Success',
  props<{ res: { data: Partial<AseUpload>[]; totalCount: number } }>()
);

export const loadAseUploadListFailure = createAction(
  '[Ase Upload/API] Load Ase Upload List Failure',
  props<{ msg: any }>()
);


// 导出Ase检查列表
export const exportAseUploadList = createAction(
  '[Ase Upload/API] Export Ase Upload List',
  // props<{ data: any }>()
);

export const exportAseUploadListSuccess = createAction(
  '[Ase Upload/API] Export Ase Upload List Success',
  props<{ res: any }>()
);

export const exportAseUploadListFailure = createAction(
  '[Ase Upload/API] Export Ase Upload List Failure',
  props<{ msg: any }>()
);

// 删除Ase检查
export const deleteAseUpload = createAction(
  '[Ase Upload/API] Delete Ase Upload Item',
  props<{ ids: string }>()
)

export const deleteAseUploadSuccess = createAction(
  '[Ase Upload/API] Delete Ase Upload Item Success',
  props<{ res: any }>()
)

export const deleteAseUploadFailure = createAction(
  '[Ase Upload/API] Delete Ase Upload Item Failure',
  props<{ msg: any }>()
)

