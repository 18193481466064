import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromMessageGroupList from './message-group-list.reducer';
import * as fromMessageGroupDetail from './message-group-detail.reducer';

export const featureKey = 'messageGroup';

export interface MessageGroupState {
  [fromMessageGroupList.featureKey]: fromMessageGroupList.State;
  [fromMessageGroupDetail.featureKey]: fromMessageGroupDetail.State;
}

export interface State {
  [featureKey]: MessageGroupState;
}

export function reducers(state: MessageGroupState | undefined, action: Action) {
  return combineReducers({
    [fromMessageGroupList.featureKey]: fromMessageGroupList.reducer,
    [fromMessageGroupDetail.featureKey]: fromMessageGroupDetail.reducer
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('system-setting');
export const getMessageGroupState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// Message Group
export const getMessageGroupEntitiesState = createSelector(
  getMessageGroupState,
  state => state.messageGroupList
);

export const getMessageGroupEntitiesIsLoading = createSelector(
  getMessageGroupEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getMessageGroupIds,
  selectEntities: getMessageGroupEntities,
  selectAll: getAllMessageGroups,
  selectTotal: getTotalMessageGroup
} = fromMessageGroupList.adapter.getSelectors(getMessageGroupEntitiesState);

export const getMessageGroupList = createSelector(
  getAllMessageGroups,
  getMessageGroupEntitiesState,
  (allMessageGroupState, messageGroupEntitiesState) => {
    const list = {
      data: allMessageGroupState,
      totalCount: messageGroupEntitiesState.totalCount
    };
    return list;
  },
);

export const getMessageGroupQuery = createSelector(
  getMessageGroupEntitiesState,
  state => state.query
);

// get message group detail
export const getMessageGroupDetail = createSelector(
  getMessageGroupState,
  state => state[fromMessageGroupDetail.featureKey].data
)