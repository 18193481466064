import { createReducer, on } from '@ngrx/store';
import {
  RulesApiActions,
  // GradeEditPageActions,
} from '../actions';
import { sequence } from '@angular/animations';

export const featureKey = 'editing';

export interface State {
  gradeDetail: any,
  paperInfo: any,
  isLoading: any,
}

export const initialState: State = {
  gradeDetail: null,
  paperInfo: null,
  isLoading: null,
}

export const reducer = createReducer(
  initialState,
  on(
    // 获取详情
    RulesApiActions.loadRulesListSuccess,
    (state, { data }) => ({
      ...state,
      gradeDetail: data
    })
  ),
  // on(
  //   // 清除
  //   GradeEditPageActions.leave,
  //   (state) => ({
  //     ...initialState
  //   })
  // ),
  // on(
  //   // 清除Dialog
  //   GradeEditPageActions.leaveDialog,
  //   (state) => ({
  //     ...state,
  //   })
  // ),
  on(
    RulesApiActions.loadRulesList,
    (state) => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    RulesApiActions.loadRulesListSuccess,
    RulesApiActions.loadRulesListFailure,
    (state) => ({
      ...state,
      isLoading: false
    })
  ),
  // on(
  //   RulesApiActions.getPaperInfoSuccess,
  //   (state, { res }) => ({
  //     ...state,
  //     paperInfo: res
  //   })
  // )

);
