import { Injectable } from '@angular/core';

@Injectable()
export class DownloadFileService {

  constructor() { }

  url(filename, fileUrl) {
    const element = document.createElement('a');
    element.setAttribute('target', '_blank');
    element.setAttribute('href', fileUrl);
    element.setAttribute('download', filename);

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
