import { createReducer, on } from '@ngrx/store';
import { SystemCodesActions } from '../actions';
import { SystemCodes } from '../models/system-codes.model';

export const featureKey = 'systemCodesDetail';

export interface State {
  data: SystemCodes | null;
}

export const initialState: State = {
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    // 讀取
    SystemCodesActions.loadDetailInfoSuccess,
    (state, { data }) => ({
      ...state,
      data
    })
  ),
  on(
    // 新增
    SystemCodesActions.addSubCodeSuccess,
    (state) => ({ ...state, ...initialState })
  ),
  on(
    // 更新
    SystemCodesActions.updateDetailSuccess,
    (state) => ({ ...state, ...initialState })
  ),
);
