import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromList from './rules-list.reducer';
import * as fromEditing from './rules-editing.reducer';

export const featureKey = 'rules';

export interface GradeState {
  [fromList.featureKey]: fromList.State;
  [fromEditing.featureKey]: fromEditing.State;
}

export interface State {
  [featureKey]: GradeState;
}

export function reducers(state: GradeState | undefined, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEditing.featureKey]: fromEditing.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('scoreSetting');
export const getGradeState = createSelector(
  getParentState,
  state => state[featureKey]
);


// list
// ----------------------------------------------------------------------------

// export const getGradeEntitiesState = createSelector(
//   getGradeState,
//   state => state.list.data
// );

// export const {
//   selectIds: getGradeIds,
//   selectEntities: getGradeEntities,
//   selectAll: getAllGrade,
//   selectTotal: getTotalGrade
// } = fromList.adapter.getSelectors(getGradeEntitiesState);

// export const getRulesList = createSelector(
//   getAllGrade,
//   getGradeEntitiesState,
//   (allGradeState, GradeEntitiesState) => {
//     console.log("");
//     const list = {
//       data: allGradeState,
//       // totalCount: GradeEntitiesState.totalCount
//     };
//     return list;
//   },
// );

export const getRulesList = createSelector(
  getGradeState,
  state => state.editing.gradeDetail
);

// export const getGradeListQuery = createSelector(
//   getGradeEntitiesState,
//   state => state.query
// );

export const getListIsLoading = createSelector(
  getGradeState,
  state => state.list.isPending
);
// Grade Detail
// ----------------------------------------------------------------------------

// export const getGradeDetail = createSelector(
//   getGradeState,
//   state => state.editing.gradeDetail
// );

// export const getPaperInfo = createSelector(
//   getGradeState,
//   state => state.editing.paperInfo
// );