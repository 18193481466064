import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { ProgressPlanManagemenListQuery } from '../models/progress-plan-managemen-list-query.model';

export const applyFilter = createAction(
  '[Inspection Record Page] Apply Progress Target Managemen List Page Filter',
  props<{ query: Partial<ProgressPlanManagemenListQuery> }>()
);

export const resetFilter = createAction('[Inspection Record Page] Reset Progress Target Managemen List Page Filter');

export const changeSort = createAction(
  '[Inspection Record Page] Change Progress Target Managemen List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Progress Plan Page] Change Progress Plan Managemen List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Progress Plan Page] Change Progress Target Managemen List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Progress Plan Page] Change Progress Plan Managemen List Page Size',
  props<{ size: number }>()
);

export const applyProgressPlanFilter = createAction(
  '[Progress Plan Page] Apply Progress Plan List Page Filter',
  props<{ query: Partial<ProgressPlanManagemenListQuery> }>()
);

export const resetProgressPlanFilter = createAction('[Progress Plan Page] Reset Progress Plan List Page Filter');

export const changeProgressPlanSort = createAction(
  '[Progress Plan Page] Change Progress Plan List Page Sort',
  props<{ currentSort: any }>()
);

export const changeProgressPlanOrderBies = createAction(
  '[Progress Plan Page] Change Progress Plan List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeProgressPlanCurrentPage = createAction(
  '[Progress Plan Page] Change Progress Plan List Current Page',
  props<{ page: number }>()
);

export const changeProgressPlanPageSize = createAction(
  '[Progress Plan Page] Change Progress Plan List Page Size',
  props<{ size: number }>()
);
