import { createReducer, on } from '@ngrx/store';
import { ProjectAssessInquiryApiActions, ProjectAssessInquiryEditActions } from '../actions';

export const featureKey = 'editing';

export type State = any | null;

export const initialState: State = {
  detail: null,
  inspectionFormList: null,
  isPending: null
};

export const reducer = createReducer(
  initialState,
  on(
    ProjectAssessInquiryApiActions.loadInspectionResultsSuccess,
    (state, { res }) => ({ ...state, detail: res, inspectionFormList: res.inspectionFormList })
  ),
);
