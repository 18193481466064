import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromCorrectiveActionRecordList from './corrective-action-record-list.reducer';

export const featureKey = 'selfCorrectiveActionRecord';

export interface CorrectiveActionRecordState {
  [fromCorrectiveActionRecordList.featureKey]: fromCorrectiveActionRecordList.State;
}

export interface State {
  [featureKey]: CorrectiveActionRecordState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromCorrectiveActionRecordList.featureKey]: fromCorrectiveActionRecordList.reducer,
  })(state, action);
}

export const getInspectionState = createFeatureSelector<State>('qpe');
export const getCorrectiveActionRecordState = createSelector(
  getInspectionState,
  state => state[featureKey]
);

// Corrective Action Record
export const getCorrectiveActionRecordEntitiesState = createSelector(
  getCorrectiveActionRecordState,
  state => state.list
);

export const getCorrectiveActionRecordEntitiesIsLoading = createSelector(
  getCorrectiveActionRecordEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getCorrectiveActionRecordIds,
  selectEntities: getCorrectiveActionRecordEntities,
  selectAll: getAllCorrectiveActionRecordInquiries,
  selectTotal: getTotalCorrectiveActionRecord
} = fromCorrectiveActionRecordList.adapter.getSelectors(getCorrectiveActionRecordEntitiesState);

export const getCorrectiveActionRecordList = createSelector(
  getAllCorrectiveActionRecordInquiries,
  getCorrectiveActionRecordEntitiesState,
  (allCorrectiveActionRecordState, correctiveActionRecordEntitiesState) => {
    const list = {
      data: allCorrectiveActionRecordState,
      totalCount: correctiveActionRecordEntitiesState.totalCount
    };
    return list;
  },
);

export const getCorrectiveActionRecordQuery = createSelector(
  getCorrectiveActionRecordEntitiesState,
  state => state.query
);
