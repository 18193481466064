import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProgressTargetManagemen } from '../models/progress-target-managemen.model';
import { ProgressTargetManagemenPageActions, ProgressTargetManagemenApiActions } from '../actions';

// Query
import { ProgressTargetManagemenListQuery } from '../models/progress-target-managemen-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'progressTargetList';

export interface State extends EntityState<Partial<ProgressTargetManagemen>> {
  query: ProgressTargetManagemenListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<ProgressTargetManagemen>> = createEntityAdapter<ProgressTargetManagemen>({
  // selectId: (progressTargetManagemen: ProgressTargetManagemen) => progressTargetManagemen.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    businessLine: null, // 业务线
    org_operation: null, // 大区
    org_area: null, // 区域
    org_program: null, // 项目
    inspector: null,
    submissionDateFrom: null,
    submissionDateTo: null,
    year:null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: false
});

export const reducer = createReducer(
  initialState,
  on(
    ProgressTargetManagemenApiActions.loadProgressTargetListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount };
      return adapter.addAll(res.data, newState);
    }),
  on(ProgressTargetManagemenPageActions.applyFilter,
    ProgressTargetManagemenPageActions.applyIssueReportFilter,
    (state, { query }) => ({
      ...state,
      query: { ...state.query, ...query }
    })),
  on(ProgressTargetManagemenPageActions.resetFilter,
    ProgressTargetManagemenPageActions.resetIssueReportFilter,
    (state) => ({
      ...state,
      query: initialState.query
    })),
  on(ProgressTargetManagemenPageActions.changeSort,
    ProgressTargetManagemenPageActions.changeIssueReportSort,
    (state, { currentSort }) => ({
      ...state,
      query: {
        ...state.query,
        currentSort: [...currentSort]
      },
      isLoading: false
    })),
  on(ProgressTargetManagemenPageActions.changeOrderBies,
    ProgressTargetManagemenPageActions.changeIssueReportOrderBies,
    (state, { orderBies }) => ({
      ...state,
      query: {
        ...state.query,
        orderBies: [...orderBies]
      },
      isLoading: false
    })),
  on(ProgressTargetManagemenPageActions.changeCurrentPage,
    ProgressTargetManagemenPageActions.changeIssueReportCurrentPage,
    (state, { page }) => ({
      ...state,
      query: {
        ...state.query,
        currentPage: page
      },
      isLoading: false
    })),
  on(ProgressTargetManagemenPageActions.changePageSize,
    ProgressTargetManagemenPageActions.changeIssueReportPageSize,
    (state, { size }) => ({
      ...state,
      query: {
        ...state.query,
        pageSize: size
      },
      isLoading: false
    })),
);
