import { props, createAction } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { ProjectAssessInquiryListQuery } from '../models/pro-assess-inquiry-list-query.model';

// List Actions
export const applyFilter = createAction(
  '[Peoject Assessment List Page] Apply Inspection List Page Filter',
  props<{ query: Partial<ProjectAssessInquiryListQuery> }>()
);

export const resetFilter = createAction('[Peoject Assessment List Page] Reset Peoject Assessment List Page Filter');

export const changeSort = createAction(
  '[Peoject Assessment List Page] Change Peoject Assessment List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Peoject Assessment List Page] Change Peoject Assessment List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Peoject Assessment List Page] Change Inspection List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Peoject Assessment List Page] Change Peoject Assessment List Page Size',
  props<{ size: number }>()
);

export const deleteInspection = createAction(
  '[Peoject Assessment List Page] Delete Inspection ',
  props<{ id: number }>()
);