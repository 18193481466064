import { createAction, props } from '@ngrx/store';
import { RoleManagementListFilter } from '../models';

export const applyFilter = createAction(
  '[Role Management List Page] Apply List Filter',
  props<{ query: RoleManagementListFilter }>()
);

export const resetFilter = createAction(
  '[Role Management List Page] Reset List Filter'
);

export const changeSort = createAction(
  '[Role Management List Page] Change List Page Sort',
  props<{ sort: any }>()
);

export const changeCurrentPage = createAction(
  '[Role Management List Page] Change List Current Page',
  props<{ currentPage: number }>()
);

export const changePageSize = createAction(
  '[Role Management List Page] Change List Page Size',
  props<{ pageSize: number }>()
);
