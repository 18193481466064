import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { CorrectiveActionRecordListQuery } from '../models/corrective-action-record-list-query.model';

export const applyFilter = createAction(
  '[Corrective Action Record Page] Apply Corrective Action Record List Page Filter',
  props<{ query: Partial<CorrectiveActionRecordListQuery> }>()
);

export const resetFilter = createAction('[Corrective Action Record Page] Reset Corrective Action Record List Page Filter');

export const changeSort = createAction(
  '[Corrective Action Record Page] Change Corrective Action Record List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Corrective Action Record Page] Change Corrective Action Record List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Corrective Action Record Page] Change Corrective Action Record List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Corrective Action Record Page] Change Corrective Action Record List Page Size',
  props<{ size: number }>()
);

export const applyIssueFilter = createAction(
  '[Corrective Action Record Page] Apply Issue Corrective Action Record List Page Filter',
  props<{ query: Partial<CorrectiveActionRecordListQuery> }>()
);

export const resetIssueFilter = createAction('[Corrective Action Record Page] Reset Issue Corrective Action Record List Page Filter');

export const changeIssueSort = createAction(
  '[Corrective Action Record Page] Change Issue Corrective Action Record List Page Sort',
  props<{ currentSort: any }>()
);

export const changeIssueOrderBies = createAction(
  '[Corrective Action Record Page] Change Issue Corrective Action Record List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeIssueCurrentPage = createAction(
  '[Corrective Action Record Page] Change Issue Corrective Action Record List Current Page',
  props<{ page: number }>()
);

export const changeIssuePageSize = createAction(
  '[Corrective Action Record Page] Change Issue Corrective Action Record List Page Size',
  props<{ size: number }>()
);
