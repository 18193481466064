import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SettingsTargetManagemen } from '../models/settings-target-managemen.model';
import { SettingsTargetManagemenApiActions } from '../actions';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'settings';

export interface State extends EntityState<Partial<SettingsTargetManagemen>> {

}

export const adapter: EntityAdapter<Partial<SettingsTargetManagemen>> = createEntityAdapter<SettingsTargetManagemen>({
  sortComparer: false
});

export const initialState = {

};

export const reducer = createReducer(
  initialState,
  on(
    SettingsTargetManagemenApiActions.getSettingsTargetInfoSuccess,
    (state, { res }) => ({ ...state, res })
  ),
);
