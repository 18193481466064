import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IncidentInquiry } from '../models/incident-inquiry.model';
import { IncidentInquiryPageActions, IncidentInquiryApiActions } from '../actions';

// Query
import { IncidentInquiryListQuery } from '../models/incident-inquiry-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'incidentInquiryList';

export interface State extends EntityState<Partial<IncidentInquiry>> {
  query: IncidentInquiryListQuery;
  totalCount: number | null;
  isLoading: boolean;
  isPendingExport: boolean;
}

export const adapter: EntityAdapter<Partial<IncidentInquiry>> = createEntityAdapter<IncidentInquiry>({
  selectId: (incidentInquiry: IncidentInquiry) => incidentInquiry.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    status: null,// 進度 System code
    incidentDateFrom: null,// ISO8601 "2019-09-11T06:04:35.000Z" 截止日期
    incidentDateTo: null,// ISO8601 "2019-09-11T06:04:35.000Z" 截止日期
    code: null,// 編號
    subject: null,// 事件标题

    // 進階搜尋
    facility: null,// 工作場所範圍內(工作區域) system code
    incidentLocation: null,// 事故地點
    department: null,// 部門/權責單位 (只有長榮使用)
    involvedPerson: null,// 事故當事人
    createdBy: null,// 通報人
    responsiblePerson: null,// 負責人 (工作者代表) (職安室窗口)
    org_operation: null,
    org_area: null,
    org_program: null,
    incidentType: null,
    involvedType: null,
    businessLine: null,
    incidentCategory: null,
    injuryDefined: null,
    isPassive: null,
    includeOrNot: null,
    isOvertime: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true,
  isPendingExport: false,
});

export const reducer = createReducer(
  initialState,
  on(IncidentInquiryApiActions.loadIncidentInquiryList, (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    IncidentInquiryApiActions.loadIncidentInquiryListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    IncidentInquiryApiActions.loadIncidentInquiryListFailure,
    (state, { msg }) => ({ ...state, msg })
  ),
  on(IncidentInquiryPageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(IncidentInquiryPageActions.resetFilter, (state) => ({
    ...state,
    query: initialState.query
  })),
  on(IncidentInquiryPageActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(IncidentInquiryPageActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(IncidentInquiryPageActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(IncidentInquiryPageActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
  on(
    IncidentInquiryApiActions.exportList,
    (state) => ({
      ...state,
      isPendingExport: true
    })
  ),
  on(
    IncidentInquiryApiActions.exportListSuccess,
    IncidentInquiryApiActions.exportListFailure,
    (state) => ({
      ...state,
      isPendingExport: false
    })
  )
);
