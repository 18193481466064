import { createAction, props } from '@ngrx/store';

// 获取SpaceType List
export const loadSpaceTypeList = createAction(
  '[SpaceType Management/API] Load SpaceType List'
);

export const loadSpaceTypeListSuccess = createAction(
  '[SpaceType Management/API] Load SpaceType List Success',
  props<{ res: { data: any[]; totalCount: number } }>()
);

export const loadSpaceTypeListFailure = createAction(
  '[SpaceType Management/API] Load SpaceType List Failure',
  props<{ res: any }>()
);

// 根据id获取role detail
export const getSpaceTypeDetail = createAction(
  '[SpaceType Management/API] Get SpaceType Detail',
  props<{ id: string }>()
);

export const getSpaceTypeDetailSuccess = createAction(
  '[SpaceType Management/API] Get SpaceType Detail Success',
  props<{ res: any }>()
);

export const getSpaceTypeDetailFailure = createAction(
  '[SpaceType Management/API] Get SpaceType Detail Failure',
  props<{ res: any }>()
);

// 角色基本信息保存
export const saveSpaceTypeBasicInfo = createAction(
  '[SpaceType Management/API] Save SpaceType Basic Info',
  props<{ para: any }>()
);

export const saveSpaceTypeBasicInfoSuccess = createAction(
  '[SpaceType Management/API] Save SpaceType Basic Info Success',
  props<{ res: any }>()
);

export const saveSpaceTypeBasicInfoFailure = createAction(
  '[SpaceType Management/API] Save SpaceType Basic Info Failure',
  props<{ res: any }>()
);

// 角色基本信息更新
export const updateSpaceTypeBasicInfo = createAction(
  '[SpaceType Management/API] Update SpaceType Basic Info',
  props<{ para: any }>()
);

export const updateSpaceTypeBasicInfoSuccess = createAction(
  '[SpaceType Management/API] Update SpaceType Basic Info Success',
  props<{ res: any }>()
);

export const updateSpaceTypeBasicInfoFailure = createAction(
  '[SpaceType Management/API] Update SpaceType Basic Info Failure',
  props<{ res: any }>()
);

// 删除角色
export const deleteSpaceTypes = createAction(
  '[SpaceType Management/API] Delete SpaceTypes',
  props<{ ids: string }>()
);

export const deleteSpaceTypesSuccess = createAction(
  '[SpaceType Management/API] Delete SpaceTypes Success',
  props<{ res: any }>()
);

export const deleteSpaceTypesFailure = createAction(
  '[SpaceType Management/API] Delete SpaceTypes Failure',
  props<{ res: any }>()
);

// 获取角色模块列表（树形）
export const getSpaceTypeMenuTreeList = createAction(
  '[SpaceType Management/API] Get SpaceType Menu Tree List'
)

export const getSpaceTypeMenuTreeListSuccess = createAction(
  '[SpaceType Management/API] Get SpaceType Menu Tree List Success',
  props<{ res: any }>()
)

export const getSpaceTypeMenuTreeListFailure = createAction(
  '[SpaceType Management/API] Get SpaceType Menu Tree List Failure',
  props<{ res: any }>()
)

// 根据树形结构List保存角色权限
export const saveSpaceTypeMenuByTreeList = createAction(
  '[SpaceType Management/API] Save SpaceType Permissions By Tree List',
  props<{ id: string, para: any }>()
)
 
export const saveSpaceTypeMenuByTreeListSuccess = createAction(
  '[SpaceType Management/API] Save SpaceType Permissions By Tree List Success',
  props<{ res: any }>()
)

export const saveSpaceTypeMenuByTreeListFailure = createAction(
  '[SpaceType Management/API] Save SpaceType Permissions By Tree List Failure',
  props<{ res: any }>()
)

// 获取role的permission tree list
export const getCurrentSpaceTypeMenuTreeList = createAction(
  '[SpaceType Management/API] Get Current SpaceType Menu Tree List',
  props<{ id: string }>()
)

export const getCurrentSpaceTypeMenuTreeListSuccess = createAction(
  '[SpaceType Management/API] Get Current SpaceType Menu Tree List Success',
  props<{ res: any }>()
)

export const getCurrentSpaceTypeMenuTreeListFailure = createAction(
  '[SpaceType Management/API] Get Current SpaceType Menu Tree List Failure',
  props<{ res: any }>()
)
