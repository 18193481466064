import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

// Service
import { SystemCodeService } from '@_core/services/system-code.service';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

@Injectable()
export class RegulationImprovementSystemCodeService {
  improvementCategoryList$ = new BehaviorSubject<any[]>([]);
  systemCodeServiceSubscription: Subscription;

  constructor(
    private systemCodeService: SystemCodeService
  ) { }

  loadSystemCode() {
    if (this.systemCodeServiceSubscription) {
      this.systemCodeServiceSubscription.unsubscribe();
    }
    this.systemCodeServiceSubscription = this.getSystemCodeServiceSubscription();
  }

  unsubscribeSystemCode() {
    this.systemCodeServiceSubscription.unsubscribe();
  }

  private getSystemCodeServiceSubscription() {
    const {
      regulationTaskStatusList,
      regulationImprovementCategoryList
    } = SystemCodeMap;

    return this.systemCodeService
      .loadCodes([
        regulationTaskStatusList,
        regulationImprovementCategoryList
      ])
      .subscribe(data => {
        this.improvementCategoryList$.next(data[regulationImprovementCategoryList].childCodes);
      });
  }
}
