import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  RulesApiActions,
  // GradeListPageActions
} from '../actions';

// Models
import { GradeListQuery } from '../models/grade-list-query.model';
import { number } from '@amcharts/amcharts4/core';

export const featureKey = 'list';

// export interface State extends EntityState<any> {
//   query: GradeListQuery;
//   msg: string | null;
//   totalCount: number | null;
// }
// export interface State {
//   rulesList: any,
//   // isLoading: boolean[];
//   // paperInfo: any,
//   isLoading: any,
// }

export interface State {
  data: null;
  isPending: boolean;
}
export const initialState: State = {
  data: null,
  isPending: false,
};

export const reducer = createReducer(
  initialState,
  on(
    // 讀取合規文件樹
    RulesApiActions.loadRulesListSuccess,
    (state, { data }) => ({
      ...state,
      data
    })
  ),
);

// export const initialState: State = {
//   rulesList: null,
//   // isLoading: [false, false, false, false, false, false, false, false, false, false, false],

//   // paperInfo: null,
//   isLoading: null,
// }

// export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
//   selectId: (grade: any) => grade.id,
//   sortComparer: false
// });

// export const initialState: State = adapter.getInitialState({
//   query: {
//     employeeName: null,
//     categoryName: null,
//     organization: null,
//     status: null,
//     // attestationName: null,
//     result: null,
//     businessLine: null,
//     org_operation: null,
//     org_area: null,
//     org_program: null,
//     // roles: null,
//     examiner:null,

//     currentPage: 1,
//     pageSize: 10,
//     orderBies: [
//       { columnName: 'id', orderType: 'desc' }
//     ],
//     currentSort: [
//       { selector: null, desc: null }
//     ]
//   },
//   msg: null,
//   totalCount: null
// });

// export const reducer = createReducer(
//   initialState,
//   on(
//     GradeApiActions.loadRulesListSuccess,
//     (state, { res }) => {
//       const newState = { ...state, totalCount: res.totalCount };
//       return adapter.addAll(res.data, newState);
//     }),
//   on(
//     GradeApiActions.loadRulesListFailure,
//     (state, { res }) => ({ ...state, res })
//   ),
//   on(GradeListPageActions.applyFilter, (state, { query }) => ({
//     ...state,
//     query: { ...state.query, ...query }
//   })),
//   // needs to change
//   on(GradeListPageActions.resetFilter, (state) => ({
//     ...state,
//     query: initialState.query
//   })),
//   on(GradeListPageActions.changeSort, (state, { currentSort }) => ({
//     ...state,
//     query: {
//       ...state.query,
//       currentSort: [...currentSort]
//     }
//   })),
//   on(GradeListPageActions.changeOrderBies, (state, { orderBies }) => ({
//     ...state,
//     query: {
//       ...state.query,
//       orderBies: [...orderBies]
//     }
//   })),
//   on(GradeListPageActions.changeCurrentPage, (state, { page }) => ({
//     ...state,
//     query: {
//       ...state.query,
//       currentPage: page
//     }
//   })),
//   on(GradeListPageActions.changePageSize, (state, { size }) => ({
//     ...state,
//     query: {
//       ...state.query,
//       pageSize: size
//     }
//   })),
// );

// export const reducer = createReducer(
//   initialState,
//   on(
//     RulesApiActions.loadRulesListSuccess,
//         (state, { data }) => {
//           console.log("res::::::",data);
//           const newState = { ...state, rulesList: data };
//           // return adapter.addAll(res.data, newState);
//           return newState
//         }),
//       on(
//         RulesApiActions.loadRulesListFailure,
//         (state, { msg }) => ({ ...state, msg })
//       ),
//   // on(
//   //   // 获取详情
//   //   GradeApiActions.loadRulesListSuccess,
//   //   (state, { res }) => (
//   //     {
//   //     ...state,
//   //     rulesList: res
//   //   })
//   // ),
//   // on(
//   //   GradeApiActions.loadRulesListSuccess,
//   //   (state, { res }) => {
//   //     // let newIsLoading = state.isLoading;
//   //     // newIsLoading[6] = false;
//   //     const newState = {
//   //       ...state,
//   //       isLoading: true,
//   //       rulesList: res
//   //     };
//   //     return newState;
//   //   }
//   // ),
//   // on(
//   //   // 清除
//   //   GradeApiActions.leave,
//   //   (state) => ({
//   //     ...initialState
//   //   })
//   // ),
//   // on(
//   //   // 清除Dialog
//   //   GradeEditPageActions.leaveDialog,
//   //   (state) => ({
//   //     ...state,
//   //   })
//   // ),
//   on(
//     RulesApiActions.loadRulesList,
//     (state) => ({
//       ...state,
//       isLoading: true
//     })
//   ),
//   on(
//     RulesApiActions.loadRulesListSuccess,
//     RulesApiActions.loadRulesListFailure,
//     (state) => ({
//       ...state,
//       isLoading: false
//     })
//   ),
//   // on(
//   //   RulesApiActions.getPaperInfoSuccess,
//   //   (state, { res }) => ({
//   //     ...state,
//   //     paperInfo: res
//   //   })
//   // )

// );
