import { createAction, props } from '@ngrx/store';
import { SpaceInspection } from '../models/space-inspection.model';

// 获取qpe检查列表（环境服务）
export const loadQPEInspectionFormList = createAction(
  '[QPE Inspection/API] Load Space Inspection Form List'
);

export const loadQPEInspectionFormListSuccess = createAction(
  '[QPE Inspection/API] Load Space Inspection Form List Success',
  props<{ res: { data: Partial<SpaceInspection>[]; totalCount: number } }>()
);

export const loadQPEInspectionFormListFailure = createAction(
  '[QPE Inspection/API] Load Space Inspection Form List Failure',
  props<{ msg: any }>()
);

// 保存qpe表格
export const updateQpeInspection = createAction(
  '[Add QPE Inspection/API] Update QPE Inspection Form',
  props<{ data: any }>()
);

export const updateQpeInspectionSuccess = createAction(
  '[Add QPE Inspection/API] Update QPE Inspection Form Success',
  props<{ res: any }>()
);

export const updateQpeInspectionFailure = createAction(
  '[Add QPE Inspection/API] Update QPE Inspection Form Failure',
  props<{ msg: any }>()
);