import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { AseManagementListQuery } from '../models/ase-management-list-query.model';

// 空间服务
export const applyFilter = createAction(
  '[ASE Management Page] Apply ASE Management List Form Page Filter',
  props<{ query: Partial<AseManagementListQuery> }>()
);

export const resetFilter = createAction('[ASE Management Page] Reset ASE Management List Form Page Filter');

export const changeSort = createAction(
  '[ASE Management Page] Change ASE Management List Form Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[ASE Management Page] Change ASE Management List Form Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[ASE Management Page] Change ASE Management List Form Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[ASE Management Page] Change ASE Management List Form Page Size',
  props<{ size: number }>()
);
