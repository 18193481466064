import { createReducer, on } from '@ngrx/store';
import {
  SettingsPlanManagemenApiActions as PageAction,
} from '../actions';
import { AddSettingsPlanManagemen } from '../models/settings-plan-managemen.model';

export const featureKey = 'edit';

export type State = AddSettingsPlanManagemen | null;

export const initialState = {

};

export const reducer = createReducer(
  initialState,
  on(
    PageAction.getLastTargetPlanSuccess,
    (state, { idAndType }) => ({ ...state, idAndType })
  ),
  on(
    PageAction.updateBasicInfo,
    (state, { basicInfo }) => ({ ...state, ...basicInfo })
  ),
  on(
    PageAction.SettingprojectPlanDescDTOs,
    (state, { projectPlanDescDTOs }) => ({ ...state, projectPlanDescDTOs })
  ),
  on(
    PageAction.getOrgInfo,
    (state, { org }) => ({ ...state, org })
  ),
  on(
    PageAction.setPlanType,
    (state, { planType }) => ({ ...state, planType })
  ),
  on(
    // 清除
    PageAction.leave,
    (state) => ({
      ...initialState
    })
  )
);
