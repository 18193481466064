import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IncidentInvestigation } from '../models/incident-investigation.model';
import { IncidentInvestigationPageActions, IncidentInvestigationApiActions } from '../actions';

// Query
import { IncidentInvestigationListQuery } from '../models/incident-investigation-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'incidentInvestigationList';

export interface State extends EntityState<Partial<IncidentInvestigation>> {
  query: IncidentInvestigationListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<IncidentInvestigation>> = createEntityAdapter<IncidentInvestigation>({
  selectId: (incidentInvestigation: IncidentInvestigation) => incidentInvestigation.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    status: null,// 進度 System code
    incidentDateFrom: null,// ISO8601 "2019-09-11T06:04:35.000Z" 截止日期
    incidentDateTo: null,// ISO8601 "2019-09-11T06:04:35.000Z" 截止日期
    id: null,
    code: null,// 編號
    subject: null,// 事件标题

    // 進階搜尋
    facility: null,// 工作場所範圍內(工作區域) system code
    incidentLocation: null,// 事故地點
    department: null,// 部門/權責單位 (只有長榮使用)
    involvedPerson: null,// 事故當事人
    createdBy: null,// 通報人
    // responsiblePerson: null,// 負責人 (工作者代表) (職安室窗口)

    incidentCategory: null,// 事件類型 system code
    hazardType: null,// 災害類型 system code
    injuredArea: null,// 受傷部位 system code
    taskBeingPerformed: null,// 工作名稱
    unsafeCondition: null,// 不安全狀況 system code
    unsafeBehavior: null,// 不安全行為 system code
    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true
});

export const reducer = createReducer(
  initialState,
  on(IncidentInvestigationApiActions.loadIncidentInvestigationList, (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    IncidentInvestigationApiActions.loadIncidentInvestigationListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    IncidentInvestigationApiActions.loadIncidentInvestigationListFailure,
    (state, { msg }) => ({ ...state, msg })
  ),
  on(IncidentInvestigationPageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(IncidentInvestigationPageActions.resetFilter, (state) => ({
    ...state,
    query: initialState.query
  })),
  on(IncidentInvestigationPageActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(IncidentInvestigationPageActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(IncidentInvestigationPageActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(IncidentInvestigationPageActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
