import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { SpaceInspectionListQuery } from '../models/space-inspection-list-query.model';

// 空间服务
export const applyFilter = createAction(
  '[QPE Inspection Page] Apply QPE Inspection List Form Page Filter',
  props<{ query: Partial<SpaceInspectionListQuery> }>()
);

export const resetFilter = createAction('[QPE Inspection Page] Reset QPE Inspection List Form Page Filter');

export const changeSort = createAction(
  '[QPE Inspection Page] Change QPE Inspection List Form Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[QPE Inspection Page] Change QPE Inspection List Form Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[QPE Inspection Page] Change QPE Inspection List Form Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[QPE Inspection Page] Change QPE Inspection List Form Page Size',
  props<{ size: number }>()
);
