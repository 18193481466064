import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromList from './grade-list.reducer';
import * as fromEditing from './grade-editing.reducer';

export const featureKey = 'Grade';

export interface GradeState {
  [fromList.featureKey]: fromList.State;
  [fromEditing.featureKey]: fromEditing.State;
}

export interface State {
  [featureKey]: GradeState;
}

export function reducers(state: GradeState | undefined, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEditing.featureKey]: fromEditing.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('attestation');
export const getGradeState = createSelector(
  getParentState,
  state => state[featureKey]
);


// list
// ----------------------------------------------------------------------------

export const getGradeEntitiesState = createSelector(
  getGradeState,
  state => state.list
);

export const {
  selectIds: getGradeIds,
  selectEntities: getGradeEntities,
  selectAll: getAllGrade,
  selectTotal: getTotalGrade
} = fromList.adapter.getSelectors(getGradeEntitiesState);

export const getGradeList = createSelector(
  getAllGrade,
  getGradeEntitiesState,
  (allGradeState, GradeEntitiesState) => {
    const list = {
      data: allGradeState,
      totalCount: GradeEntitiesState.totalCount
    };
    console.log('allGradeStateallGradeState',allGradeState);
    return list;
  },
);

export const getGradeListQuery = createSelector(
  getGradeEntitiesState,
  state => state.query
);

export const getListIsLoading = createSelector(
  getGradeState,
  state => state.editing.isLoading
);
// Grade Detail
// ----------------------------------------------------------------------------

export const getGradeDetail = createSelector(
  getGradeState,
  state => state.editing.gradeDetail
);

export const getPaperInfo = createSelector(
  getGradeState,
  state => state.editing.paperInfo
);
