import { createAction, props } from '@ngrx/store';
import { CorrectiveActionRecord } from '../models/corrective-action-record.model';

export const loadCorrectiveActionRecordList = createAction(
  '[Self Corrective Action Record/API] Load Self Corrective Action Record List'
);

export const loadCorrectiveActionRecordListSuccess = createAction(
  '[Self Corrective Action Record/API] Load Self Corrective Action Record List Success',
  props<{ res: { data: Partial<CorrectiveActionRecord>[]; totalCount: number } }>()
);

export const loadCorrectiveActionRecordListFailure = createAction(
  '[Self Corrective Action Record/API] Load Self Corrective Action Record List Failure',
  props<{ res: any }>()
);
