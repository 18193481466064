import { createAction, props } from '@ngrx/store';
import { ProgressTargetManagemen } from '../models/progress-target-managemen.model';

export const loadProgressTargetList = createAction(
  '[Load Progress Target List/API] Load Progress Target List'
);

export const loadProgressTargetListSuccess = createAction(
  '[Load Progress Target List/API] Load Progress Target List Success',
  props<{ res: { data: Partial<ProgressTargetManagemen>[]; totalCount: number } }>()
);

export const loadProgressTargetListFailure = createAction(
  '[Load Progress Target List/API] Load Progress Target List Failure',
  props<{ res: any }>()
);

// 导出列表
export const exportList = createAction(
  '[Inspection Record/API1219] Export Inspection List',
  props<{ para: any }>()
);

export const exportListSuccess = createAction(
  '[Inspection Record/API1219] Export Inspection List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Inspection Record/API1219] Export Inspection List Failure',
  props<{ msg: any }>()
);

// 删除列表
export const delProgressTarget = createAction(
  '[Del Progress Target/API] Del Progress Target',
  props<{ id: any }>()
);

export const delProgressTargetSuccess = createAction(
  '[Del Progress Target/API] Del Progress Target Success',
);

export const delProgressTargetFailure = createAction(
  '[Del Progress Target/API] Del Progress Target Failure',
  props<{ msg: any }>()
);
