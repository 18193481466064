import { createReducer, on } from '@ngrx/store';
import { DocClassificationApiActions } from '../actions';
import { DocClassification } from '../models/doc-classification.model';

export const featureKey = 'docClassificationDetail';

export interface State {
  isPending: boolean;
  data: DocClassification | null;
}

export const initialState: State = {
  isPending: false,
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    // 讀取
    DocClassificationApiActions.loadDetailInfoSuccess,
    (state, { data }) => ({
      ...state,
      data
    })
  ),
  on(
    DocClassificationApiActions.deleteDocClassificationsSuccess,
    (state) => ({ ...state, ...initialState })
  ),
  on(
    DocClassificationApiActions.addDocClassification,
    DocClassificationApiActions.deleteDocClassifications,
    DocClassificationApiActions.updateDetail,
    (state) => ({ ...state, isPending: true })
  ),
  on(
    DocClassificationApiActions.addDocClassificationSuccess,
    DocClassificationApiActions.addDocClassificationFailure,
    DocClassificationApiActions.deleteDocClassificationsSuccess,
    DocClassificationApiActions.deleteDocClassificationsFailure,
    DocClassificationApiActions.updateDetailSuccess,
    DocClassificationApiActions.updateDetailFailure,
    (state) => ({ ...state, isPending: false })
  ),
);
