import { props, createAction } from '@ngrx/store';
import { Doc } from '../models/doc-mgnt-edit.model';

// 讀取列表
export const loadDocList = createAction(
  '[Doc List/API] Load Project Report List'
);

export const loadDocListSuccess = createAction(
  '[Doc List/API] Load Project Report Success',
  props<{ res: { data: any; totalCount: number } }>()
);

export const loadDocListFailure = createAction(
  '[Doc List/API] Load Project Report Failure',
  props<{ res: any }>()
);

// 編輯特定組織下的合規文件
export const editProjectDoc = createAction(
  '[Doc/API] Edit Project Report Doc',
  props<{ doc: any }>()
);

export const editProjectSuccess = createAction(
  '[Doc/API] Edit Project Report Success',
  props<{ res: any }>()
);

export const editProjectFailure = createAction(
  '[Doc/API] Edit Project Report Failure',
  props<{ res: any }>()
);
