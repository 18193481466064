import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

// Service
import { SystemCodeService } from '@_core/services/system-code.service';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

@Injectable()
export class RegulationTaskSystemCodeService {
  systemCodeServiceSubscription: Subscription;
  applicabilityList$ = new BehaviorSubject<any[]>([]);
  applicabilityOptions$ = new BehaviorSubject<any[]>([]);
  passStatusList$ = new BehaviorSubject<any[]>([]);
  passOptions$ = new BehaviorSubject<any[]>([]);

  constructor(
    private systemCodeService: SystemCodeService
  ) { }

  loadSystemCode(): void {
    if (this.systemCodeServiceSubscription) {
      this.systemCodeServiceSubscription.unsubscribe();
    }
    this.systemCodeServiceSubscription = this.getSystemCodeServiceSubscription();
  }

  unsubscribeSystemCode(): void {
    this.systemCodeServiceSubscription.unsubscribe();
  }

  private getSystemCodeServiceSubscription(): Subscription {
    const {
      regulationApplicableStatusList,
      regulationApplicableStatus,
      regulationTaskStatusList,
      regulationCallOutStatusList,
      regulationPassStatusList,
      regulationPassStatus,
      regulationContentCategoryList,
    } = SystemCodeMap;

    return this.systemCodeService
      .loadCodes([
        regulationApplicableStatusList,
        regulationTaskStatusList,
        regulationCallOutStatusList,
        regulationPassStatusList,
        regulationContentCategoryList,
      ])
      .subscribe(data => {
        const { applicable, notApplicable } = regulationApplicableStatus;
        const applicabilityList = data[regulationApplicableStatusList].childCodes;
        this.applicabilityList$.next(applicabilityList);
        this.applicabilityOptions$.next(applicabilityList.filter(item => item.code === applicable || item.code === notApplicable));

        const { passed, notPassed } = regulationPassStatus;
        const passStatusList = data[regulationPassStatusList].childCodes;
        this.passStatusList$.next(passStatusList);
        this.passOptions$.next(passStatusList.filter(item => item.code === passed || item.code === notPassed));
      });
  }
}
