import { createReducer, on } from '@ngrx/store';
import {
  DocProjectApiActions,
  DocProjectPageActions
} from '../actions';

export const featureKey = 'editingManagement';

export interface State {
  isPending: boolean;
  data: null;
}

export const initialState: State = {
  isPending: true,
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    DocProjectApiActions.editProjectDoc,
    (state) => ({ ...state, isPending: true })
  ),
  on(
    DocProjectApiActions.editProjectSuccess,
    (state) => ({ ...state, isPending: false })
  ),
  on(
    DocProjectApiActions.editProjectFailure,
    (state) => ({ ...state, isPending: false })
  ),
);
