import { createReducer, on } from '@ngrx/store';
import {
  GradeApiActions,
  GradeEditPageActions,
} from '../actions';
import { sequence } from '@angular/animations';

export const featureKey = 'editing';

export interface State {
  gradeDetail: any,
  paperInfo: any,
  isLoading: any,
}

export const initialState: State = {
  gradeDetail: null,
  paperInfo: null,
  isLoading: null,
}

export const reducer = createReducer(
  initialState,
  on(
    // 获取详情
    GradeApiActions.getGradeDetailSuccess,
    (state, { res }) => ({
      ...state,
      gradeDetail: res
    })
  ),
  on(
    // 清除
    GradeEditPageActions.leave,
    (state) => ({
      ...initialState
    })
  ),
  on(
    // 清除Dialog
    GradeEditPageActions.leaveDialog,
    (state) => ({
      ...state,
    })
  ),
  on(
    GradeApiActions.loadGradeList,
    (state) => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    GradeApiActions.loadGradeListSuccess,
    GradeApiActions.loadGradeListFailure,
    (state) => ({
      ...state,
      isLoading: false
    })
  ),
  on(
    GradeApiActions.getPaperInfoSuccess,
    (state, { res }) => ({
      ...state,
      paperInfo: res
    })
  )

);
