import { createAction, props } from '@ngrx/store';
import { SettingsTargetManagemen } from '../models/settings-target-managemen.model';

export const loadInspectionRecordList = createAction(
  '[Inspection Record/API1219] Load Progress Target Managemen List'
);

export const loadInspectionRecordListSuccess = createAction(
  '[Inspection Record/API1219] Load Progress Target Managemen List Success',
  props<{ res: { data: Partial<SettingsTargetManagemen>[]; totalCount: number } }>()
);

export const loadInspectionRecordListFailure = createAction(
  '[Inspection Record/API1219] Load Progress Target Managemen List Failure',
  props<{ res: any }>()
);

// 根据组织架构获取指标
export const getSettingTargetBaseInfo = createAction(
  '[Get Setting Target Base Info/API] Get Setting Target Base Info',
  props<{ orgId }>()
);

export const getSettingTargetBaseInfoSuccess = createAction(
  '[Get Setting Target Base Info/API] Get Setting Target Base Info Success',
  props<{ targetList }>()
);

export const getSettingTargetBaseInfoFailure = createAction(
  '[Get Setting Target Base Info/API] Get Setting Target Base Info Failure',
  props<{ res: any }>()
);

// 获取上财年
export const getSettingTargetLastYear = createAction(
  '[Get Setting Target Last Year/API] Get Setting Target Last Year',
  props<{ lastInfo }>()
);

export const getSettingTargetLastYearSuccess = createAction(
  '[Get Setting Target Last Year/API] Get Setting Target Last Year Success',
  props<{ lastYearList }>()
);

export const getSettingTargetLastYearFailure = createAction(
  '[Get Setting Target Last Year/API] Get Setting Target Last Year Failure',
  props<{ res: any }>()
);

// 保存目标设定基本信息
export const saveTargetBasic = createAction(
  '[Save Target Basic/API] Save Target Basic',
  props<{ targetBasic }>()
);

// 保存目标设定指标信息
export const saveTargetList = createAction(
  '[Save Target List/API] Save Target List',
  props<{ kpiDetailDTOS }>()
);


// 新增目标设定
export const addSettingsTarget = createAction(
  '[Add Settings Target/API] Add Settings Target',
);

export const addSettingsTargetSuccess = createAction(
  '[Add Settings Target/API] Add Settings Target Success',
  props<{ id }>()
);

export const addSettingsTargetFailure = createAction(
  '[Add Settings Target/API] Add Settings Target Failure',
  props<{ res: any }>()
);

// 修改目标设定
export const updateSettingsTarget = createAction(
  '[Update Settings Target/API] Update Settings Target',
);

export const updateSettingsTargetSuccess = createAction(
  '[Update Settings Target/API] Update Settings Target Success',
  props<{ id }>()
);

export const updateSettingsTargetFailure = createAction(
  '[Update Settings Target/API] Update Settings Target Failure',
  props<{ res: any }>()
);

// 发送邮件目标设定
export const sendEmailSettingsTarget = createAction(
  '[Send Email Settings Target/API] Send Email  Settings Target',
);

export const sendEmailSettingsTargetSuccess = createAction(
  '[Send Email  Settings Target/API] Send Email  Settings Target Success',
  props<{ id }>()
);

export const sendEmailSettingsTargetFailure = createAction(
  '[Send Email  Settings Target/API] Send Email  Settings Target Failure',
  props<{ res: any }>()
);



export const settingsTargetOrg = createAction(
  '[Settings Target Org] Settings Target Org',
  props<{ org }>()
);


export const leave = createAction(
  '[Settings Target Leave Page] Settings Target Leave'
);


// 讀取详情
export const getSettingsTargetInfo = createAction(
  '[Get Settings Target Info/API] Get Settings Target Info',
  props<{ id:number }>()
);

export const getSettingsTargetInfoSuccess = createAction(
  '[Get Settings Target Info/API] Get Settings Target Info Success',
  props<{ res }>()
);

export const getSettingsTargetInfoFailure = createAction(
  '[Get Settings Target Info/API] Get Settings Target Info Failure',
  props<{ res: any }>()
);



// 获取kpi
export const getTargetKpi = createAction(
  '[Get Target Last Kpi/API] Get Target Last Kpi',
  props<{ kpiInfo }>()
);

export const getTargetKpiSuccess = createAction(
  '[Get Target Last Kpi/API] Get Target Last Kpi Success',
  props<{ kpiList }>()
);

export const getTargetKpiFailure = createAction(
  '[Get Target Last Kpi/API] Get Target Last Kpi Failure',
  props<{ res: any }>()
);
