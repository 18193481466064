import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action
} from '@ngrx/store';
import * as fromList from './pro-assess-inquiry-list.reducer';
import * as fromEditing from './pro-assess-inquiry-edit.reducer';

export const featureKey = 'projectAssessmentInquiry';

export interface ProjectAssessInquiryState {
  [fromList.featureKey]: fromList.State;
  [fromEditing.featureKey]: fromEditing.State;
}

export interface State {
  [featureKey]: ProjectAssessInquiryState;
}

export function reducers(state: ProjectAssessInquiryState | undefined, action: Action) {
  return combineReducers({
    [fromList.featureKey]: fromList.reducer,
    [fromEditing.featureKey]: fromEditing.reducer,
  })(state, action);
}

export const getParentState = createFeatureSelector<State>('qpe');
export const getProjectAssessInquiryState = createSelector(
  getParentState,
  state => state[featureKey]
);


// list
// ----------------------------------------------------------------------------

export const getProjectAssessInquiryEntitiesState = createSelector(
  getProjectAssessInquiryState,
  state => state.list
);

export const {
  selectIds: getProjectAssessInquiryIds,
  selectEntities: getProjectAssessInquiryEntities,
  selectAll: getAllProjectAssessInquiry,
  selectTotal: getTotalProjectAssessInquiry
} = fromList.adapter.getSelectors(getProjectAssessInquiryEntitiesState);

export const getProjectAssessInquiryList = createSelector(
  getAllProjectAssessInquiry,
  getProjectAssessInquiryEntitiesState,
  (allProjectAssessInquiryState, govPenaltyEntitiesState) => {
    const list = {
      data: allProjectAssessInquiryState,
      totalCount: govPenaltyEntitiesState.totalCount
    };
    return list;
  },
);

export const getProjectAssessInquiryListQuery = createSelector(
  getProjectAssessInquiryEntitiesState,
  state => state.query
);

export const getProjectAssessInquiryListIsLoading = createSelector(
  getProjectAssessInquiryState,
  state => state.list.isLoading
);

// detail
// ----------------------------------------------------------------------------

export const getEditingProjectAssessmentInquiry = createSelector(
  getProjectAssessInquiryState,
  state => state.editing
);

export const getProjectAssessmentDetail = createSelector(
  getEditingProjectAssessmentInquiry,
  state => state.detail
);

export const getProjectAssessmentForms = createSelector(
  getEditingProjectAssessmentInquiry,
  state => state.inspectionFormList
);
