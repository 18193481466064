import { createReducer, on } from '@ngrx/store';
import { RoleManagementApiActions as ApiActions, RoleManagementPageActions as PageActions } from '../actions';

export const featureKey = 'edit';

export interface State {
  isPending: boolean;
  roleDetail: any;
  roleId: number;
  roleMenuTreeList: any;
  currentRoleMenuTreeList: any;
}

export const initialState: State = {
  isPending: false,
  roleDetail: null,
  roleId: null,
  roleMenuTreeList: null,
  currentRoleMenuTreeList: null,
};

export const reducer = createReducer(
  initialState,
  on(ApiActions.getRoleDetailSuccess, (state, { res }) => ({
    ...state,
    roleDetail: res.DATA
  })),
  on(
    ApiActions.saveRoleMenuByTreeList, (state) => ({
      ...state,
      isPending: true
    })
  ),
  on(
    ApiActions.saveRoleMenuByTreeListSuccess,
    ApiActions.saveRoleMenuByTreeListFailure,
    (state) => ({
      ...state,
      isPending: false
    })
  ),
  on(
    ApiActions.saveRoleBasicInfo,
    (state) => ({
      ...state,
      isPending: true
    })
  ),
  on(
    ApiActions.saveRoleBasicInfoSuccess,
    ApiActions.saveRoleBasicInfoFailure,
    (state, { res }) => ({
    ...state,
    roleId: res.DATA,
    isPending: false
  })),
  on(ApiActions.getRoleMenuTreeListSuccess, (state, { res }) => ({
    ...state,
    roleMenuTreeList: res.DATA
  })),
  on(ApiActions.getCurrentRoleMenuTreeListSuccess, (state, { res }) => ({
    ...state,
    currentRoleMenuTreeList: res.DATA
  })),
);
