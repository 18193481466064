var manager = null
const config = {
  authority: 'https://sso.aramark.cn',
  redirect_uri: document.location.origin + '/callback',
  post_logout_redirect_uri: document.location.origin,
  client_id: 'AramarkEHSQ',
  response_type: 'code',
  scope: 'openid profile',
}
manager = new Oidc.UserManager(config);

export default {
  data () {
    return {
      Mgr: manager
    }
  },
  callback () {
    new Oidc.UserManager({response_mode: 'query'}).signinRedirectCallback().then(function () {
      window.location.replace('/auto-login');
    }).catch(function (e) {
      console.log(e);
    });
  }
}
