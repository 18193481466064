import { createReducer, on } from '@ngrx/store';
import { SpaceTypeApiActions as ApiActions, SpaceTypePageActions as PageActions } from '../actions';

export const featureKey = 'edit';

export interface State {
  // isPending: boolean;
  roleDetail: any;
  roleId: number;
  roleMenuTreeList: any;
  currentSpaceTypeMenuTreeList: any;
}

export const initialState: State = {
  // isPending: false,
  roleDetail: null,
  roleId: null,
  roleMenuTreeList: null,
  currentSpaceTypeMenuTreeList: null,
};

export const reducer = createReducer(
  initialState,
  on(ApiActions.getSpaceTypeDetailSuccess, (state, { res }) => ({
    ...state,
    roleDetail: res.DATA
  })),
  on(ApiActions.saveSpaceTypeBasicInfoSuccess, (state, { res }) => ({
    ...state,
    roleId: res.DATA
  })),
  on(ApiActions.getSpaceTypeMenuTreeListSuccess, (state, { res }) => ({
    ...state,
    roleMenuTreeList: res.DATA
  })),
  on(ApiActions.getCurrentSpaceTypeMenuTreeListSuccess, (state, { res }) => ({
    ...state,
    currentSpaceTypeMenuTreeList: res.DATA
  })),
);
