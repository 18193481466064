import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CorrectiveActionRecord } from '../models/corrective-action-record.model';
import { CorrectiveActionRecordPageActions, CorrectiveActionRecordApiActions } from '../actions';

// Query
import { CorrectiveActionRecordListQuery } from '../models/corrective-action-record-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'list';

export interface State extends EntityState<Partial<CorrectiveActionRecord>> {
  query: CorrectiveActionRecordListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<CorrectiveActionRecord>> = createEntityAdapter<CorrectiveActionRecord>({
  selectId: (correctiveActionRecord: CorrectiveActionRecord) => correctiveActionRecord.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    status: null,// 進度 System code
    correctiveActionRecordDateFrom: null,// ISO8601 "2019-09-11T06:04:35.000Z" 截止日期
    correctiveActionRecordDateTo: null,// ISO8601 "2019-09-11T06:04:35.000Z" 截止日期
    code: null,// 編號
    subject: null, // 任務編號
    formSelector: null,
    businessLine: null,
    org_operation: null,
    org_area: null,
    org_program: null,
    inspectionMethod: null,
    inspectionCategory: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: false
});

export const reducer = createReducer(
  initialState,
  on(CorrectiveActionRecordApiActions.loadCorrectiveActionRecordList,
    (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    CorrectiveActionRecordApiActions.loadCorrectiveActionRecordListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount, isLoading: false };
      return adapter.addAll(res.data, newState);
    }),
  on(
    CorrectiveActionRecordApiActions.loadCorrectiveActionRecordListFailure,
    (state, { res }) => ({ ...state, res, isLoading: false })
  ),
  on(CorrectiveActionRecordPageActions.applyFilter,
     (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(CorrectiveActionRecordPageActions.resetFilter,
     (state) => ({
    ...state,
    query: initialState.query
  })),
  on(CorrectiveActionRecordPageActions.changeSort,
     (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(CorrectiveActionRecordPageActions.changeOrderBies,
     (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(CorrectiveActionRecordPageActions.changeCurrentPage,
     (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(CorrectiveActionRecordPageActions.changePageSize,
     (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
