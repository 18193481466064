import { createReducer, on } from '@ngrx/store';
import { DepartmentManagementApiActions as ApiActions, DepartmentManagementPageActions as PageActions } from '../actions';

export const featureKey = 'edit';

export interface State {
  // isPending: boolean;
  deptDetail: any;
}

export const initialState: State = {
  // isPending: false,
  deptDetail: null,
};

export const reducer = createReducer(
  initialState,
  on(ApiActions.getDepartmentManagementDetailSuccess,
    (state, { res }) => ({
      ...state,
      deptDetail: res.DATA
    })),
  on(ApiActions.clearDetail,
    (state) => ({
      ...state,
      deptDetail: null
    }))

);
