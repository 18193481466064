import { createAction, props } from '@ngrx/store';
import { SystemCodes } from '../models/system-codes.model';
import { QueryUtilsModel } from '@_core/models/logic-custom-query/query-utils.model';

// 获取系统代码信息
export const loadDetailInfo = createAction(
    '[SystemCodes/API] Load SystemCodes Detail Info',
    props<{ code: string }>()
);

export const loadDetailInfoSuccess = createAction(
    '[SystemCodes/API] Load SystemCodes Detail Info Success',
    props<{ data: any }>()
);

export const loadDetailInfoFailure = createAction(
    '[SystemCodes/API] Load SystemCodes Incident Detail Info Failure',
    props<{ msg: any }>()
);

// 新增子代码
export const addSubCode = createAction(
    '[SystemCodes/API] Add SubSystemCodes Detail',
    props<{ detail: Partial<SystemCodes> }>()
);

export const addSubCodeSuccess = createAction(
    '[SystemCodes/API] Add SubSystemCodes Detail Success',
    props<{ res: any }>()
);

export const addSubCodeFailure = createAction(
    '[SystemCodes/API] Add SubSystemCodes Detail Failure',
    props<{ msg: any }>()
);

// 更新系统代码
export const updateDetail = createAction(
    '[SystemCodes/API] Update SystemCodes Detail',
    props<{ detail: Partial<SystemCodes> }>()
);

export const updateDetailSuccess = createAction(
    '[SystemCodes/API] Update SystemCodes Detail Success',
    props<{ res: any }>()
);

export const updateDetailFailure = createAction(
    '[SystemCodes/API] Update SystemCodes Detail Failure',
    props<{ msg: any }>()
);

// 获取系统代码树
export const loadSystemCodesTreeInfo = createAction(
    '[SystemCodes/API] Load SystemCodes Tree Info',
    props<{ code: string }>()
);

export const loadSystemCodesTreeInfoSuccess = createAction(
    '[SystemCodes/API] Load SystemCodes Tree Info Success',
    props<{ data: any }>()
);

export const loadSystemCodesTreeInfoFailure = createAction(
    '[SystemCodes/API] Load SystemCodes Tree Info Failure',
    props<{ msg: any }>()
);
