import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromIncidentInquiryList from './incident-inquiry-list.reducer';

export const featureKey = 'incidentInquiry';

export interface IncidentInquiryState {
  [fromIncidentInquiryList.featureKey]: fromIncidentInquiryList.State;
}

export interface State {
  [featureKey]: IncidentInquiryState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromIncidentInquiryList.featureKey]: fromIncidentInquiryList.reducer,
  })(state, action);
}

export const getIncidentState = createFeatureSelector<State>('incident');
export const getIncidentInquiryState = createSelector(
  getIncidentState,
  state => state.incidentInquiry
);

// Incident Inquiry
export const getIncidentInquiryEntitiesState = createSelector(
  getIncidentInquiryState,
  state => state.incidentInquiryList
);

export const getIncidentInquiryEntitiesIsLoading = createSelector(
  getIncidentInquiryEntitiesState,
  state => state.isLoading
);

export const getIncidentInquiryEntitiesIsPendingExport = createSelector(
  getIncidentInquiryEntitiesState,
  state => state.isPendingExport
);

export const {
  selectIds: getIncidentInquiryIds,
  selectEntities: getIncidentInquiryEntities,
  selectAll: getAllIncidentInquiries,
  selectTotal: getTotalIncidentInquiry
} = fromIncidentInquiryList.adapter.getSelectors(getIncidentInquiryEntitiesState);

export const getIncidentInquiryList = createSelector(
  getAllIncidentInquiries,
  getIncidentInquiryEntitiesState,
  (allIncidentInquiryState, incidentInquiryEntitiesState) => {
    const list = {
      data: allIncidentInquiryState,
      totalCount: incidentInquiryEntitiesState.totalCount
    };
    return list;
  },
);

export const getIncidentInquiryQuery = createSelector(
  getIncidentInquiryEntitiesState,
  state => state.query
);
