import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  DocProjectApiActions,
  DocProjectPageActions
} from '../actions';

// Models
import { Doc } from '../models/doc-mgnt-edit.model';
import { DocProjectQuery } from '../models/doc-project-query.model';

export const featureKey = 'listProject';

export interface State extends EntityState<Partial<Doc>> {
  query: DocProjectQuery;
  msg: string | null;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<Doc>> = createEntityAdapter<Doc>({
  selectId: (doc: Doc) => doc.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    businessLine: null,
    org_operation: null,
    org_area: null,
    org_program: null,
    effectiveDateStart: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'effective_date_start', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(DocProjectApiActions.loadDocList, (state) => ({
    ...state,
    isLoading: true
  })),
  on(
    DocProjectApiActions.loadDocListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount };
      return adapter.addAll(res.data, newState);
    }),
  on(
    DocProjectApiActions.loadDocListFailure,
    (state, { res }) => ({ ...state, res })
  ),
  on(DocProjectPageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(DocProjectPageActions.resetFilter, (state) => {
    return {
      ...state,
      query: initialState.query
    };
  }),
  on(DocProjectPageActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(DocProjectPageActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(DocProjectPageActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(DocProjectPageActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
