import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BulletinBoardDataPageActions, BulletinBoardDataApiActions } from '../actions';
import { BulletinBoardData } from '../models/bulletin-board-data.model';

// Query
import { BulletinBoardDataListQuery } from '../models/bulletin-board-data-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'bulletionList';

export interface State extends EntityState<Partial<any>> {
  query: BulletinBoardDataListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<any>> = createEntityAdapter<any>({
  // selectId: (Satisfaction: Satisfaction) => Satisfaction.section,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    org_operation: null,
    org_area: null,
    org_program: null,
    businessLine: null,
    serviceType: null,
    month: null,
    isInspect:null,
    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: true
});

export const reducer = createReducer(
  initialState,
  on(
    BulletinBoardDataApiActions.loadBulletinList,
    (state) => ({
    ...state,
    isLoading: true
  })),
  // on(
  //   BulletinBoardDataApiActions.loadBulletinfactionListSuccess,
  //   (state, { res }) => {
  //     const newState = { ...state, totalCount: res.totalCount, isLoading: false };
  //     return adapter.addAll(res.data, newState);
  //   }),
  on(
    BulletinBoardDataPageActions.applyFilter,
    (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  on(
    BulletinBoardDataPageActions.resetFilter,
    (state) => ({
    ...state,
    query: initialState.query
  })),
  on(
    BulletinBoardDataPageActions.changeSort,
    (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    },
    isLoading: true
  })),
  on(
    BulletinBoardDataPageActions.changeOrderBies,
    (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    },
    isLoading: true
  })),
  on(
    BulletinBoardDataPageActions.changeCurrentPage,
    (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    },
    isLoading: true
  })),
  on(
    BulletinBoardDataPageActions.changePageSize,
    (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    },
    isLoading: true
  })),
);
