import { from } from 'rxjs';
import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers,
  createReducer, on
} from '@ngrx/store';
// import * as fromSettingPlanManagemenList from './settings-plan-managemen.reducer';
import * as fromEdit from './settings-plan-edit.reducer';
import * as fromEditing from './settings-plan-editing.reducer'
export const featureKey = 'settingPlanManagemen';

export interface progressPlanManagemenState {
  [fromEdit.featureKey]: fromEdit.State;
  [fromEditing.featureKey]: fromEditing.State;
}

export interface State {
  [featureKey]: progressPlanManagemenState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromEdit.featureKey]: fromEdit.reducer,
    [fromEditing.featureKey]: fromEditing.reducer,
  })(state, action);
}

export const getTargetAndPlanState = createFeatureSelector<State>('target-and-plan');
export const getSettingPlanManagemenState = createSelector(
  getTargetAndPlanState,
  state => state[featureKey]
);


export const getEditingTargetPlan = createSelector(
  getSettingPlanManagemenState,
  state => state.edit
);
export const getBasicInfo = createSelector(
  getEditingTargetPlan,
  state => state.basicInfo
);


export const getProjectPlanDescDTOs = createSelector(
  getEditingTargetPlan,
  state => state.projectPlanDescDTOs
);


export const getEditingPlanInfo = createSelector(
  getSettingPlanManagemenState,
  state => state.editing
);

export const getOrgInfo = createSelector(
  getEditingTargetPlan,
  state => state.org
);

export const setPlanType = createSelector(
  getEditingTargetPlan,
  state => state.planType
);


export const getLastPlan = createSelector(
  getEditingTargetPlan,
  state => state.idAndType
);

