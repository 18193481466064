import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  GradeApiActions,
  GradeListPageActions
} from '../actions';

// Models
import { GradeListQuery } from '../models/grade-list-query.model';
import { number } from '@amcharts/amcharts4/core';

export const featureKey = 'list';

export interface State extends EntityState<any> {
  query: GradeListQuery;
  msg: string | null;
  totalCount: number | null;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (grade: any) => grade.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    employeeName: null,
    categoryName: null,
    organization: null,
    status: null,
    // attestationName: null,
    result: null,
    businessLine: null,
    org_operation: null,
    org_area: null,
    org_program: null,
    // roles: null,
    examiner:null,
    noPassRecord:null,
    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null
});

export const reducer = createReducer(
  initialState,
  on(
    GradeApiActions.loadGradeListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount };
      return adapter.addAll(res.data, newState);
    }),
  on(
    GradeApiActions.loadGradeListFailure,
    (state, { res }) => ({ ...state, res })
  ),
  on(GradeListPageActions.applyFilter, (state, { query }) => ({
    ...state,
    query: { ...state.query, ...query }
  })),
  // needs to change
  on(GradeListPageActions.resetFilter, (state) => ({
    ...state,
    query: initialState.query
  })),
  on(GradeListPageActions.changeSort, (state, { currentSort }) => ({
    ...state,
    query: {
      ...state.query,
      currentSort: [...currentSort]
    }
  })),
  on(GradeListPageActions.changeOrderBies, (state, { orderBies }) => ({
    ...state,
    query: {
      ...state.query,
      orderBies: [...orderBies]
    }
  })),
  on(GradeListPageActions.changeCurrentPage, (state, { page }) => ({
    ...state,
    query: {
      ...state.query,
      currentPage: page
    }
  })),
  on(GradeListPageActions.changePageSize, (state, { size }) => ({
    ...state,
    query: {
      ...state.query,
      pageSize: size
    }
  })),
);
