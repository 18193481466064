import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromAseUploadList from './ase-upload-list.reducer';

export const featureKey = 'AseUpload';

export interface AseUploadState {
  [fromAseUploadList.featureKey]: fromAseUploadList.State;
}

export interface State {
  [featureKey]: AseUploadState;
}

export function reducers(state: AseUploadState | undefined, action: Action) {
  return combineReducers({
    [fromAseUploadList.featureKey]: fromAseUploadList.reducer,
  })(state, action);
}

export const getSystemSettingState = createFeatureSelector<State>('qpe');
export const getAseUploadState = createSelector(
  getSystemSettingState,
  state => state[featureKey]
);

// Message Group
export const getAseUploadEntitiesState = createSelector(
  
  getAseUploadState,
  state => state.AseUploadList

);

export const getAseUploadEntitiesIsLoading = createSelector(
  getAseUploadEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getAseUploadIds,
  selectEntities: getAseUploadEntities,
  selectAll: getAllAseUploads,
  selectTotal: getTotalAseUpload
} = fromAseUploadList.adapter.getSelectors(getAseUploadEntitiesState);

export const getAseUploadList = createSelector(
  getAllAseUploads,
  getAseUploadEntitiesState,
  (allAseUploadState, AseUploadEntitiesState) => {
    const list = {
      data: allAseUploadState,
      totalCount: AseUploadEntitiesState.totalCount
    };
    return list;
  },
);

export const getAseUpload = createSelector(
  getAseUploadEntitiesState,
  state => state.query
);
