import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { InjuredPeopleDetail } from '../models/injured-people-detail.model';
import { InjuredPeoplePageActions, InjuredPeopleApiActions } from '../actions';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'injuredPeopleDetail';

export interface State {
  injuredPeopleDetail: any | null;
}

export const initialState: State = {
  injuredPeopleDetail: null,
};

export const reducer = createReducer(
  initialState,
  on(
    InjuredPeopleApiActions.loadInjuredPeopleDetailSuccess,
    (state, { res }) => ({
      ...state,
      injuredPeopleDetail: res
    })
  )
);
