import { createAction, props } from '@ngrx/store';
import { MessageGroup } from '../models/message-group.model';

// 获取群组列表
export const loadMessageGroupList = createAction(
  '[Message Group/API] Load Message Group List'
);

export const loadMessageGroupListSuccess = createAction(
  '[Message Group/API] Load Message Group List Success',
  props<{ res: { data: Partial<MessageGroup>[]; totalCount: number } }>()
);

export const loadMessageGroupListFailure = createAction(
  '[Message Group/API] Load Message Group List Failure',
  props<{ msg: any }>()
);

// 创建群组
export const createMessageGroup = createAction(
  '[Message Group/API] Create Message Group',
  props<{ full_name: string, org: any, users: any[] }>()
);

export const createMessageGroupSuccess = createAction(
  '[Message Group/API] Create Message Group Success',
  props<{ res: { data: Partial<MessageGroup>[]; totalCount: number } }>()
);

export const createMessageGroupFailure = createAction(
  '[Message Group/API] Create Message Group Failure',
  props<{ msg: any }>()
);

// 更新群组
export const updateMessageGroup = createAction(
  '[Message Group/API] Update Message Group',
  props<{ id: number, full_name: string, org: any, users: any[] }>()
);

export const updateMessageGroupSuccess = createAction(
  '[Message Group/API] Update Message Group Success',
  props<{ res: number }>()
);

export const updateMessageGroupFailure = createAction(
  '[Message Group/API] Update Message Group Failure',
  props<{ msg: any }>()
);

// 获取群组详细信息
export const getMessageGroupDetail = createAction(
  '[Message Group/API] Get Message Group Detail',
  props<{ id: number }>()
)

export const getMessageGroupDetailSuccess = createAction(
  '[Message Group/API] Get Message Group Detail Success',
  props<{ res: any }>()
)

export const getMessageGroupDetailFailure = createAction(
  '[Message Group/API] Get Message Group Detail Failure',
  props<{ msg: any }>()
)

// 删除群组
export const deleteMessageGroup = createAction(
  '[Message Group/API] Delete Message Group',
  props<{ ids: string }>()
)

export const deleteMessageGroupSuccess = createAction(
  '[Message Group/API] Delete Message Group Detail Success',
  props<{ res: any }>()
)

export const deleteMessageGroupFailure = createAction(
  '[Message Group/API] Delete Message Group Detail Failure',
  props<{ msg: any }>()
)
