import { createReducer, on } from '@ngrx/store';
import { DocClassificationApiActions } from '../actions';
import { DocClassification } from '../models/doc-classification.model';

export const featureKey = 'docClassificationTree';

export interface State {
  data: DocClassification[];
}

export const initialState: State = {
  data: []
};

export const reducer = createReducer(
  initialState,
  on(
    // 讀取合規文件樹
    DocClassificationApiActions.loadDocClassificationTreeInfoSuccess,
    (state, { data }) => ({
      ...state,
      data
    })
  ),
);
