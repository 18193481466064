import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { AseUploadListModel } from '../models/ase-upload-list.model';

// 空间服务
export const applyFilter = createAction(
  '[Space Upload Page] Apply Space Upload List Page Filter',
  props<{ query: Partial<AseUploadListModel> }>()
);

export const resetFilter = createAction('[Space Upload Page] Reset Space Upload List Page Filter');

export const changeSort = createAction(
  '[Space Upload Page] Change Space Upload List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBies = createAction(
  '[Space Upload Page] Change Space Upload List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Space Upload Page] Change Space Upload List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Space Upload Page] Change Space Upload List Page Size',
  props<{ size: number }>()
);

// 运送服务
export const applyFilterDeliver = createAction(
  '[Space Upload Deliver Page] Apply Space Upload List Page Filter',
  props<{ query: Partial<AseUploadListModel> }>()
);

export const resetFilterDeliver = createAction('[Space Upload Deliver Page] Reset Space Upload List Page Filter');

export const changeSortDeliver = createAction(
  '[Space Upload Deliver Page] Change Space Upload List Page Sort',
  props<{ currentSort: any }>()
);

export const changeOrderBiesDeliver = createAction(
  '[Space Upload Deliver Page] Change Space Upload List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPageDeliver = createAction(
  '[Space Upload Deliver Page] Change Space Upload List Current Page',
  props<{ page: number }>()
);

export const changePageSizeDeliver = createAction(
  '[Space Upload Deliver Page] Change Space Upload List Page Size',
  props<{ size: number }>()
);

