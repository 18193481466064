import { Injectable } from '@angular/core';
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

@Injectable()
export class RegulationImprovementPermissionService {
  taskStatus = SystemCodeMap.regulationTaskStatus;
  responsibility = SystemCodeMap.responsibilityTypes;
  canAcceptTask(roles: string[], taskStatus: string): boolean {
    const { waiting } = this.taskStatus;
    const { assignee } = this.responsibility;
    if (
      (taskStatus === waiting && roles.includes(assignee))
    ) {
      return true;
    }
    return false;
  }

  canEditTask(roles: string[], taskStatus: string): boolean {
    const { improving } = this.taskStatus;
    const { assignee } = this.responsibility;
    if (
      (taskStatus === improving && roles.includes(assignee))
    ) {
      return true;
    }
    return false;
  }

  canSignTask(roles: string[], taskStatus: string): boolean {
    const { reviewing } = this.taskStatus;
    const { assignee } = this.responsibility;
    if (
      (taskStatus === reviewing && roles.includes(assignee))
    ) {
      return true;
    }
    return false;
  }

}
