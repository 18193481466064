import { createAction, props } from '@ngrx/store';
import { CorrectiveAction, Issue, InspectionBasicInfo,DeviationCode } from '../../_shared/models';

// set isPending to true
export const setEditPageIsPendingToTrue = createAction(
  '[Issue Reporting Edit Page] Set Edit Page isPending To True'
);

export const setEditPageIsPendingToFalse = createAction(
  '[Issue Reporting Edit Page] Set Edit Page isPending To False'
);

export const updateBasicInfo = createAction(
  '[Issue Reporting Edit Page] Update Basic Info',
  props<{ basicInfo: InspectionBasicInfo }>()
);
export const updateDeviationCode = createAction(
  '[Issue Reporting Edit Page] Update DeviationCode',
  props<{ deviationCode: DeviationCode }>()
);
export const updateFindingIssues = createAction(
  '[Issue Reporting Edit Page] Update Finding Issues',
  props<{ issues: Issue[] }>()
);

export const leave = createAction(
  '[Issue Reporting Edit Page] Leave'
);
