import { createAction, props } from '@ngrx/store';

// 获取SpaceGroup List
export const loadSpaceGroupList = createAction(
  '[SpaceGroup Management/API] Load SpaceGroup List'
);

export const loadSpaceGroupListSuccess = createAction(
  '[SpaceGroup Management/API] Load SpaceGroup List Success',
  props<{ res: { data: any[]; totalCount: number } }>()
);

export const loadSpaceGroupListFailure = createAction(
  '[SpaceGroup Management/API] Load SpaceGroup List Failure',
  props<{ res: any }>()
);

// 根据id获取role detail
export const getSpaceGroupDetail = createAction(
  '[SpaceGroup Management/API] Get SpaceGroup Detail',
  props<{ id: string }>()
);

export const getSpaceGroupDetailSuccess = createAction(
  '[SpaceGroup Management/API] Get SpaceGroup Detail Success',
  props<{ res: any }>()
);

export const getSpaceGroupDetailFailure = createAction(
  '[SpaceGroup Management/API] Get SpaceGroup Detail Failure',
  props<{ res: any }>()
);

// 角色基本信息保存
export const saveSpaceGroupBasicInfo = createAction(
  '[SpaceGroup Management/API] Save SpaceGroup Basic Info',
  props<{ para: any }>()
);

export const saveSpaceGroupBasicInfoSuccess = createAction(
  '[SpaceGroup Management/API] Save SpaceGroup Basic Info Success',
  props<{ res: any }>()
);

export const saveSpaceGroupBasicInfoFailure = createAction(
  '[SpaceGroup Management/API] Save SpaceGroup Basic Info Failure',
  props<{ res: any }>()
);

// 角色基本信息更新
export const updateSpaceGroupBasicInfo = createAction(
  '[SpaceGroup Management/API] Update SpaceGroup Basic Info',
  props<{ para: any }>()
);

export const updateSpaceGroupBasicInfoSuccess = createAction(
  '[SpaceGroup Management/API] Update SpaceGroup Basic Info Success',
  props<{ res: any }>()
);

export const updateSpaceGroupBasicInfoFailure = createAction(
  '[SpaceGroup Management/API] Update SpaceGroup Basic Info Failure',
  props<{ res: any }>()
);

// 删除角色
export const deleteSpaceGroups = createAction(
  '[SpaceGroup Management/API] Delete SpaceGroups',
  props<{ ids: string }>()
);

export const deleteSpaceGroupsSuccess = createAction(
  '[SpaceGroup Management/API] Delete SpaceGroups Success',
  props<{ res: any }>()
);

export const deleteSpaceGroupsFailure = createAction(
  '[SpaceGroup Management/API] Delete SpaceGroups Failure',
  props<{ res: any }>()
);

// 获取角色模块列表（树形）
export const getSpaceGroupMenuTreeList = createAction(
  '[SpaceGroup Management/API] Get SpaceGroup Menu Tree List'
)

export const getSpaceGroupMenuTreeListSuccess = createAction(
  '[SpaceGroup Management/API] Get SpaceGroup Menu Tree List Success',
  props<{ res: any }>()
)

export const getSpaceGroupMenuTreeListFailure = createAction(
  '[SpaceGroup Management/API] Get SpaceGroup Menu Tree List Failure',
  props<{ res: any }>()
)

// 根据树形结构List保存角色权限
export const saveSpaceGroupMenuByTreeList = createAction(
  '[SpaceGroup Management/API] Save SpaceGroup Permissions By Tree List',
  props<{ id: string, para: any }>()
)
 
export const saveSpaceGroupMenuByTreeListSuccess = createAction(
  '[SpaceGroup Management/API] Save SpaceGroup Permissions By Tree List Success',
  props<{ res: any }>()
)

export const saveSpaceGroupMenuByTreeListFailure = createAction(
  '[SpaceGroup Management/API] Save SpaceGroup Permissions By Tree List Failure',
  props<{ res: any }>()
)

// 获取role的permission tree list
export const getCurrentSpaceGroupMenuTreeList = createAction(
  '[SpaceGroup Management/API] Get Current SpaceGroup Menu Tree List',
  props<{ id: string }>()
)

export const getCurrentSpaceGroupMenuTreeListSuccess = createAction(
  '[SpaceGroup Management/API] Get Current SpaceGroup Menu Tree List Success',
  props<{ res: any }>()
)

export const getCurrentSpaceGroupMenuTreeListFailure = createAction(
  '[SpaceGroup Management/API] Get Current SpaceGroup Menu Tree List Failure',
  props<{ res: any }>()
)
