import { props, createAction } from '@ngrx/store';

// 获取列表
export const loadRulesList = createAction(
  '[Score Setting/API] Load Score Rules List'
);

export const loadRulesListSuccess = createAction(
  '[Score Setting/API] Load Score Rules List Success',
  props<{ data: any }>()
);

export const loadRulesListFailure = createAction(
  '[Score Setting/API] Load Score Rules List Failure',
  props<{ msg: any }>()
);

// 更新规则
export const updateRule = createAction(
  '[Score Setting/API] Update Score Rules',
  props<{ rule: any }>()
);

export const updateRuleSuccess = createAction(
  '[Score Setting/API] Update Score Rules Success',
  props<{ res: any }>()
);

export const updateRuleFailure = createAction(
  '[Score Setting/API] Update Score Rules Failure',
  props<{ res: any }>()
);


