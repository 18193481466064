import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ProgressPlanManagemen } from '../models/progress-plan-managemen.model';
import { ProgressPlanManagemenPageActions, ProgressPlanManagemenApiActions } from '../actions';

// Query
import { ProgressPlanManagemenListQuery } from '../models/progress-plan-managemen-list-query.model';

// Config
import { SystemCodeMap } from '@quantum-theme/core/_config/system-code-map';

export const featureKey = 'progressPlanList';

export interface State extends EntityState<Partial<ProgressPlanManagemen>> {
  query: ProgressPlanManagemenListQuery;
  totalCount: number | null;
  isLoading: boolean;
}

export const adapter: EntityAdapter<Partial<ProgressPlanManagemen>> = createEntityAdapter<ProgressPlanManagemen>({
  selectId: (progressPlanManagemen: ProgressPlanManagemen) => progressPlanManagemen.id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  query: {
    status: null,
    year: null,
    type: null,
    department_name: null,
    org_area: null,
    org_operation: null,
    org_program: null,
    businessLine: null,

    currentPage: 1,
    pageSize: 10,
    orderBies: [
      { columnName: 'id', orderType: 'desc' }
    ],
    currentSort: [
      { selector: null, desc: null }
    ]
  },
  msg: null,
  totalCount: null,
  isLoading: false
});

export const reducer = createReducer(
  initialState,
  on(
    ProgressPlanManagemenApiActions.loadProgressPlanListSuccess,
    (state, { res }) => {
      const newState = { ...state, totalCount: res.totalCount };
      return adapter.addAll(res.data, newState);
    }),
  on(
    ProgressPlanManagemenApiActions.loadSettingsPlanListFailure,
    (state, { res }) => ({ ...state, res })
  ),
  on(ProgressPlanManagemenPageActions.applyFilter,
    ProgressPlanManagemenPageActions.applyProgressPlanFilter,
    (state, { query }) => ({
      ...state,
      query: { ...state.query, ...query }
    })),
  on(ProgressPlanManagemenPageActions.resetFilter,
    ProgressPlanManagemenPageActions.resetProgressPlanFilter,
    (state) => ({
      ...state,
      query: initialState.query
    })),
  on(ProgressPlanManagemenPageActions.changeSort,
    ProgressPlanManagemenPageActions.changeProgressPlanSort,
    (state, { currentSort }) => ({
      ...state,
      query: {
        ...state.query,
        currentSort: [...currentSort]
      },
      isLoading: false
    })),
  on(ProgressPlanManagemenPageActions.changeOrderBies,
    ProgressPlanManagemenPageActions.changeProgressPlanOrderBies,
    (state, { orderBies }) => ({
      ...state,
      query: {
        ...state.query,
        orderBies: [...orderBies]
      },
      isLoading: false
    })),
  on(ProgressPlanManagemenPageActions.changeCurrentPage,
    ProgressPlanManagemenPageActions.changeProgressPlanCurrentPage,
    (state, { page }) => ({
      ...state,
      query: {
        ...state.query,
        currentPage: page
      },
      isLoading: false
    })),
  on(ProgressPlanManagemenPageActions.changePageSize,
    ProgressPlanManagemenPageActions.changeProgressPlanPageSize,
    (state, { size }) => ({
      ...state,
      query: {
        ...state.query,
        pageSize: size
      },
      isLoading: false
    })),
);
