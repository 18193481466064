import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { DocListQuery } from '../models/doc-list-query.model';

export const applyFilter = createAction(
  '[Doc List Page] Apply Doc List Page Filter',
  props<{ query: Partial<DocListQuery> }>()
);

export const resetFilter = createAction('[Doc List Page] Reset Doc List Page Filter');

export const changeSort = createAction(
  '[Doc List Page] Change Doc List Page Sort',
  props<{ currentSort: any }>()
);

export const sort = createAction(
	'[Doc List Page] Sort',
	props<{ orderBies: QueryOrderBy }>()
);

export const changeOrderBies = createAction(
  '[Doc List Page] Change Doc List Page OrderBies',
  props<{ orderBies: QueryOrderBy[] }>()
);

export const changeCurrentPage = createAction(
  '[Doc List Page] Change Doc List Current Page',
  props<{ page: number }>()
);

export const changePageSize = createAction(
  '[Doc List Page] Change Doc List Page Size',
  props<{ size: number }>()
);
