import { createAction, props } from '@ngrx/store';
import { SettingsPlanManagemen } from '../models/settings-plan-managemen.model';

export const loadSettingsPlanList = createAction(
  '[Settings Plan/API] Load Settings Plan List'
);

export const loadSettingsPlanListSuccess = createAction(
  '[Settings Plan/API1219] Load PSettings Plan List Success',
  props<{ res: { data: Partial<SettingsPlanManagemen>[]; totalCount: number } }>()
);

export const loadSettingsPlanListFailure = createAction(
  '[Inspection Record/API1219] Load Progress Plan Managemen List Failure',
  props<{ res: any }>()
);



// 新增计划设定
export const addTargetPlan = createAction(
  '[Target Plan/API] Add Target Plan'
);

export const addTargetPlanSuccess = createAction(
  '[Target Plan/API] Add Target Plan Success',
  props<{ }>()
);

export const addTargetPlanFailure = createAction(
  '[Target Plan/API] Add Target Plan Failure',
  props<{ res: any }>()
);

// 讀取详情
export const getTargetPlan = createAction(
  '[Get Target Plan/API] Get Target Plan',
  props<{ id: number }>()
);

export const getTargetPlanSuccess = createAction(
  '[Get Target Plan/API] Get Target Plan Success',
  props<{ res }>()
);

export const getTargetPlanFailure = createAction(
  '[Get Target Plan/API] Get Target Plan Failure',
  props<{ res: any }>()
);


export const getOrgInfo = createAction(
  '[Setting Org Info Page] Get Org Info',
  props<{ org }>()
);

export const setPlanType = createAction(
  '[Setting Plan Type Page] Set Plan Type',
  props<{ planType }>()
);

export const updateBasicInfo = createAction(
  '[Setting Reporting Edit Page] Update Basic Info',
  props<{ basicInfo }>()
);

export const SettingprojectPlanDescDTOs = createAction(
  '[Setting project Plan Page] Setting project Plan',
  props<{ projectPlanDescDTOs }>()
);

export const leave = createAction(
  '[Setting project Edit Page] Leave'
);


// 修改计划设定
export const updateTargetPlan = createAction(
  '[Update Target Plan/API] Update Target Plan'
);

export const updateTargetSuccess = createAction(
  '[Update Target Plan/API] Update Target Plan Success',
  props<{ }>()
);

export const updateTargetFailure = createAction(
  '[Update Target Plan/API] Update Target Plan Failure',
  props<{ res: any }>()
);


// 获取上年度项目
export const getLastTargetPlan = createAction(
  '[Get Last Target Plan/API] Get Last Target Plan',
  props<{ data }>()
);

export const getLastTargetPlanSuccess = createAction(
  '[Get Last Target Plan/API] Get Last Target Plan Success',
  props<{ idAndType }>()
);

export const getLastTargetPlanFailure = createAction(
  '[Get Last Target Plan/API] Get Last Target Plan Failure',
  props<{ res: any }>()
);


// 导出列表
export const SettingsExportList = createAction(
  '[Settings Plan Export/API1219] Settings Plan Export List',
  props<{ para: any }>()
);

export const SettingsExportListSuccess = createAction(
  '[Settings Plan Export/API1219] Settings Plan Export List Success',
  props<{ res: any }>()
);

export const SettingsExportListFailure = createAction(
  '[Settings Plan Export/API1219] Settings Plan Export List Failure',
  props<{ msg: any }>()
);
