import { createAction, props } from '@ngrx/store';
import { QueryOrderBy } from '@_core/models/logic-custom-query/query-order-by.model';
import { PostGroupListQuery } from '../models/post-group-list-query.model';

export const applyFilter = createAction(
    '[Post Group Page] Apply Post Group List Page Filter',
    props<{ query: Partial<PostGroupListQuery> }>()
  );
  
  export const resetFilter = createAction('[Post Group Page] Reset Post Group List Page Filter');
  
  export const changeSort = createAction(
    '[Post Group Page] Change Post Group List Page Sort',
    props<{ currentSort: any }>()
  );
  
  export const changeOrderBies = createAction(
    '[Post Group Page] Change Post Group List Page OrderBies',
    props<{ orderBies: QueryOrderBy[] }>()
  );
  
  export const changeCurrentPage = createAction(
    '[Post Group Page] Change Post Group List Current Page',
    props<{ page: number }>()
  );
  
  export const changePageSize = createAction(
    '[Post Group Page] Change Post Group List Page Size',
    props<{ size: number }>()
  );