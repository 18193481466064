import { createAction, props } from '@ngrx/store';

// 获取DepartmentManagement List
export const loadDepartmentManagementList = createAction(
  '[Department Management/API] Load DepartmentManagement List'
);

export const loadDepartmentManagementListSuccess = createAction(
  '[Department Management/API] Load DepartmentManagement List Success',
  props<{ res: { data: any[]; totalCount: number } }>()
);

export const loadDepartmentManagementListFailure = createAction(
  '[Department Management/API] Load DepartmentManagement List Failure',
  props<{ res: any }>()
);

// 根据id获取role detail
export const getDepartmentManagementDetail = createAction(
  '[Department Management/API] Get DepartmentManagement Detail',
  props<{ id: string }>()
);

export const getDepartmentManagementDetailSuccess = createAction(
  '[Department Management/API] Get DepartmentManagement Detail Success',
  props<{ res: any }>()
);

export const getDepartmentManagementDetailFailure = createAction(
  '[Department Management/API] Get DepartmentManagement Detail Failure',
  props<{ res: any }>()
);

// 删除角色
export const deleteDepartmentManagements = createAction(
  '[Department Management/API] Delete DepartmentManagements',
  props<{ ids: string }>()
);

export const deleteDepartmentManagementsSuccess = createAction(
  '[Department Management/API] Delete DepartmentManagements Success',
  props<{ res: any }>()
);

export const deleteDepartmentManagementsFailure = createAction(
  '[Department Management/API] Delete DepartmentManagements Failure',
  props<{ res: any }>()
);

// 创建
export const createDept = createAction(
  '[Department Management/API] Create DepartmentManagements',
  props<{ detail: any }>()
)

export const createDeptSuccess = createAction(
  '[Department Management/API] Create DepartmentManagements Success',
  props<{ res: any }>()
)

export const createDeptFailure = createAction(
  '[Department Management/API] Create DepartmentManagements Failure',
  props<{ res: any }>()
)

// 更新
export const updateDept = createAction(
  '[Department Management/API] Update DepartmentManagements',
  props<{ detail: any }>()
)

export const updateDeptSuccess = createAction(
  '[Department Management/API] Update DepartmentManagements Success',
  props<{ res: any }>()
)

export const updateDeptFailure = createAction(
  '[Department Management/API] Update DepartmentManagements Failure',
  props<{ res: any }>()
)

// clear
export const clearDetail = createAction(
  '[Department Management/API] Clear DepartmentManagements Detail'
)