import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromInjuredPeopleList from './injured-people-list.reducer';
import * as fromInjuredPeopleDetail from './injured-people-detail.reducer';

export const featureKey = 'injuredPeople';

export interface InjuredPeopleState {
  [fromInjuredPeopleList.featureKey]: fromInjuredPeopleList.State;
  [fromInjuredPeopleDetail.featureKey]: fromInjuredPeopleDetail.State;
}

export interface State {
  [featureKey]: InjuredPeopleState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromInjuredPeopleList.featureKey]: fromInjuredPeopleList.reducer,
    [fromInjuredPeopleDetail.featureKey]: fromInjuredPeopleDetail.reducer
  })(state, action);
}

export const getIncidentState = createFeatureSelector<State>('incident');
export const getInjuredPeopleState = createSelector(
  getIncidentState,
  state => state.injuredPeople
);

export const getInjuredPeopleEntitiesState = createSelector(
  getInjuredPeopleState,
  state => state.injuredPeopleList
);

export const getInjuredPeopleEntitiesIsLoading = createSelector(
  getInjuredPeopleEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getInjuredPeopleIds,
  selectEntities: getInjuredPeopleEntities,
  selectAll: getAllInjuredPeople,
  selectTotal: getTotalInjuredPeople
} = fromInjuredPeopleList.adapter.getSelectors(getInjuredPeopleEntitiesState);

export const getInjuredPeopleList = createSelector(
  getAllInjuredPeople,
  getInjuredPeopleEntitiesState,
  (allInjuredPeopleState, injuredPeopleEntitiesState) => {
    const list = {
      data: allInjuredPeopleState,
      totalCount: injuredPeopleEntitiesState.totalCount
    };
    return list;
  },
);

export const getInjuredPeopleQuery = createSelector(
  getInjuredPeopleEntitiesState,
  state => state.query
);

export const getInjuredPeopleDetailState = createSelector(
  getInjuredPeopleState,
  state => state.injuredPeopleDetail
)

export const getInjuredPeopleDetail = createSelector(
  getInjuredPeopleDetailState,
  state => state.injuredPeopleDetail
)
