import {
  createSelector,
  createFeatureSelector,
  Action,
  combineReducers
} from '@ngrx/store';
import * as fromProgressPlanManagemenList from './progress-plan-managemen-list.reducer';

export const featureKey = 'progressPlanManagemen';

export interface progressPlanManagemenState {
  [fromProgressPlanManagemenList.featureKey]: fromProgressPlanManagemenList.State;
}

export interface State {
  [featureKey]: progressPlanManagemenState;
}

export function reducers(state, action: Action) {
  return combineReducers({
    [fromProgressPlanManagemenList.featureKey]: fromProgressPlanManagemenList.reducer,
  })(state, action);
}

export const getProgressPlanState = createFeatureSelector<State>('target-and-plan');
export const getprogressPlanManagemenState = createSelector(
  getProgressPlanState,
  state => state[featureKey]
);

export const getprogressPlanManagemenEntitiesState = createSelector(
  getprogressPlanManagemenState,
  state => state.progressPlanList
);

export const getprogressPlanManagemenEntitiesIsLoading = createSelector(
  getprogressPlanManagemenEntitiesState,
  state => state.isLoading
);

export const {
  selectIds: getProgressPlanManagemenIdfs,
  selectEntities: getProgressPlanManagemenEntities,
  selectAll: getAllProgressPlanManagemen,
  selectTotal: getTotalProgressPlanManagemen
} = fromProgressPlanManagemenList.adapter.getSelectors(getprogressPlanManagemenEntitiesState);

export const getprogressPlanManagemenList = createSelector(
  getAllProgressPlanManagemen,
  getprogressPlanManagemenEntitiesState,
  (allprogressPlanManagemenState, progressPlanManagemenEntitiesState) => {
    const list = {
      data: allprogressPlanManagemenState,
      totalCount: progressPlanManagemenEntitiesState.totalCount
    };
    return list;
  },
);

export const getprogressPlanManagemenQuery = createSelector(
  getprogressPlanManagemenEntitiesState,
  state => state.query
);

