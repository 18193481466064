import { props, createAction } from '@ngrx/store';

// 获取列表
export const loadGradeList = createAction(
  '[Attestation Grade/API] Load Attestation Grade List'
);

export const loadGradeListSuccess = createAction(
  '[Attestation Grade/API] Load Attestation Grade List Success',
  props<{ res: { data: Partial<any>[]; totalCount: number } }>()
);

export const loadGradeListFailure = createAction(
  '[Attestation Grade/API] Load Attestation Grade List Failure',
  props<{ res: any }>()
);

// 获取详情
export const getGradeDetail = createAction(
  '[Attestation Grade/API] Get Attestation Grade Detail',
  props<{ id: number }>()
);

export const getGradeDetailSuccess = createAction(
  '[Attestation Grade/API] Get Attestation Grade Detail Success',
  props<{ res: any }>()
);

export const getGradeDetailFailure = createAction(
  '[Attestation Grade/API] Get Attestation Grade Detail Failure',
  props<{ res: any }>()
);

// 获得试卷信息
export const getPaperInfo = createAction(
  '[Attestation Grade/API] Get Attestation Paper Info',
  props<{ paperId: number, recordId: number, contentId: number }>()
);

export const getPaperInfoSuccess = createAction(
  '[Attestation Grade/API] Get Attestation Paper Info Success',
  props<{ res: any }>()
);

export const getPaperInfoFailure = createAction(
  '[Attestation Grade/API] Get Attestation Paper Info Failure',
  props<{ res: any }>()
);

// 导出
export const exportList = createAction(
  '[Attestation Grade/API] Export Attestation Grade List'
);

export const exportListSuccess = createAction(
  '[Attestation Grade/API] Export Attestation Grade List Success',
  props<{ res: any }>()
);

export const exportListFailure = createAction(
  '[Attestation Grade/API] Export Attestation Grade List Failure',
  props<{ msg: any }>()
);